import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "@fontsource/roboto";
import GreenCheck from "../../../assets/images/green-checkmark-line-icon.svg";

const QRCODE_EXP_TIME = 60 * 5;
const AUTOMATIC_REDIRECT = 10;

export const PixModal = ({
  svgPix,
  confirming,
  qrcodeHash,
  title,
  price,
  visible,
  onClose,
}) => {
  const [countDown, setCountDown] = useState(QRCODE_EXP_TIME);
  const [runTimer, setRunTimer] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (runTimer) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (confirming === "APROVADA") setCountDown(() => AUTOMATIC_REDIRECT);
  }, [confirming]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(QRCODE_EXP_TIME);
      if (confirming === "APROVADA") {
        navigate("../meu-perfil");
      } else {
        setStatus("FAIL");
      }
      onClose();
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const handleOnClose = () => {
    setCountDown(QRCODE_EXP_TIME);
    setRunTimer(false);
    onClose();
  };

  if (!visible) return null;

  return (
    <>
      <div
        id="container"
        className="fixed sm:max-md:static sm:max-md:h-full inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="my-6 mx-auto w-2/4 min-w-fit max-w-6xl sm:max-md:w-full">
          <div className="primary-color relative flex flex-col rounded-xl shadow-lg outline-none focus:outline-none">
            <div className="mt-5 flex flex-col p-4">
              <div className="mx-10 flex justify-between text-white">
                <div>
                  <p className="px-2 text-2xl">
                    Contrato: <b>{title}</b>
                  </p>
                  <p className="-mt-2 px-2 text-xl">
                    Documento criado no formato PDF
                  </p>
                </div>
                <p className="justify-end px-2 text-xl ">R${price}</p>
              </div>
              {confirming != "APROVADA" ? (
                <>
                  <div className="m-10 flex  justify-center">
                    <div className="bg-white p-8">
                      <object
                        width="250px"
                        height="250px"
                        className="p-3"
                        data={svgPix}
                        type="image/svg+xml">
                        <svg className="object-fit" src="temp.jpg" alt="" />
                      </object>
                    </div>
                  </div>
                  <div className="-mt-6 p-2 text-center leading-4 text-white">
                    <p className="font-roboto text-xs">
                      Você está tendo problemas para ler o cóodigo QR? o código
                      abaixo e insira-o manualmente
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="font-roboto yellow w-2/3 rounded-lg py-4 text-2xl font-bold text-black"
                      onClick={() => navigator.clipboard.writeText(qrcodeHash)}>
                      Copiar Pix Copia e Cola
                    </button>
                  </div>
                  <div className="m-5">
                    <p className="font-roboto text-center text-sm text-white">
                      {" "}
                      Você tem:{" "}
                      <span className="font-bold">
                        {minutes}:{seconds}
                      </span>{" "}
                      para efetuar a transferência e concluir o seu pedido{" "}
                    </p>
                  </div>
                </>
              ) : (
                <div className="font-roboto mt-10 text-center text-white">
                  <div className=" m-2 flex justify-center">
                    <img src={GreenCheck} alt="" />
                  </div>
                  <p className="text-xl">Pagamento confirmado. </p>
                  <p className="text-xl">
                    Você será redirecionado para a página de contratos
                    automaticamente.
                  </p>
                  <p className="text-xl">
                    Caso não ocorra,{" "}
                    <Link className="underline" to="../meu-perfil">
                      {" "}
                      CLIQUE AQUI!
                    </Link>
                  </p>
                </div>
              )}
              <div className="mx-10 my-4 text-xs text-white">
                <ul className="list-disc">
                  <li>
                    Entre no Internet Banking do seu banco preferido e selecione
                    a opção PIX.
                  </li>
                  <li>
                    Selecione a opção QR Code e escaneie o código com a câmera
                    do seu celular para efetuar o pagamento.
                  </li>
                  <li>Por fim, clique em confirmar pagamento.</li>
                  <li>
                    Verifique os detalhes do seu pedido em sua caixa de email.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="yellow font-roboto m-2 rounded-md px-10 py-2 text-lg font-semibold text-black"
              onClick={() => handleOnClose()}>
              Fechar
            </button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

import BackgroundSaq from "../../assets/images/backgroundSaq.svg";
import SymboraContact from "../../assets/images/symboraContact.svg";
import Location from "../../assets/images/icons/location.svg";
import Phone from "../../assets/images/icons/phone.svg";
import Email from "../../assets/images/icons/email.svg";
import Facebook from "../../assets/images/icons/facebook.svg";
import Instagram from "../../assets/images/icons/instagram.svg";
import Linkedin from "../../assets/images/icons/linkedin.svg";
import { useForm } from "react-hook-form";
import { sendContact } from "../../api";
import { useState } from "react";
import { ConfirmModal } from "../../components/modals/ModalConfirm";

export const Saq = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [modal, setShowModal] = useState(false);
  const message = "Mensagem enviado com sucesso.";
  const onSubmit = async (data) => {
    const response = await sendContact({
      data: {
        email: data.email,
        message: data.content,
        name: data.name,
      },
    });

    setShowModal(true);
    reset();
  };
  return (
    <div className="">
      <ConfirmModal message={message} visible={modal} onClose={setShowModal} />
      <div className="absolute -z-10">
        <img className="w-full object-cover" src={BackgroundSaq} alt="" />
      </div>
      <div className="z-10 flex flex-row justify-center">
        <div className="relative m-4 mt-40 mb-28 flex h-auto w-full max-w-4xl rounded-xl bg-white shadow-lg sm:flex-col md:flex-row ">
          <div className="m-4 w-full p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="mx-4 px-4 text-3xl font-semibold text-[#43377C]">
                Entrar em contato
              </p>
              <p className="text-primary-color mx-4 px-4">
                Estamos aqui por você! Como podemos ajudar?
              </p>
              <div className="p-4">
                <input
                  className="input-form hover:border-red w-full border-none p-4 placeholder:text-[#9F9F9F] focus:outline-none"
                  type="text"
                  placeholder="Digite seu nome"
                  {...register("name", { required: true })}
                />
              </div>
              <div className="p-4">
                <input
                  className="input-form w-full border-none p-4 placeholder:text-[#9F9F9F] focus:border-none"
                  type="text"
                  placeholder="Insira o seu endereço de email"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="p-4">
                <textarea
                  className="input-form resize-none border-none p-4 placeholder:text-[#9F9F9F] focus:outline-none sm:w-4/5 md:w-full"
                  placeholder="Vá em frente, estamos ouvindo..."
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  {...register("content", { required: true })}
                ></textarea>
              </div>
              <div className="mt-8 ml-8">
                <button
                  className="primary-color mb-6 w-full p-2 text-xl font-bold text-white hover:bg-purple-900"
                  type="submit"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
          <div className="m-4 flex flex-col">
            <div className="scale-125">
              <img className="p-10" src={SymboraContact} />
            </div>
            <div className="text-primary-color m-auto flex flex-col p-4">
              <div className="mb-4 flex">
                <img className="h-7 w-7" src={Location} alt="" />
                <p className="flex items-center text-center ">
                  São Paulo - Brasil
                </p>
              </div>
              <div className="my-4 flex">
                <img className="h-7 w-7" src={Phone} alt="" />
                <a
                  className=""
                  href="https://api.whatsapp.com/send?phone=11989056171"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="items-center text-center">11 98905-61-71</p>
                </a>
              </div>
              <div className="my-4 flex">
                <img className="h-7 w-7" src={Email} alt="" />
                <p className="items-center text-center ">
                  contato@symbora.com.br
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rectangle">
          <div className="rounded-tr-xl px-5 py-4 hover:bg-purple-800">
            <a href="https://www.facebook.com/symboraempreender" target="_blank">
              <img className="h-5 w-5" src={Facebook} alt="" />
            </a>
          </div>
          <div className="px-5 py-4 hover:bg-purple-800">
            <a href="https://www.instagram.com/symbora_empreender/" target="_blank">
              <img className="h-5 w-5" src={Instagram} alt="" />
            </a>
          </div>
          <div className="rounded-br-2xl px-5 py-4 hover:bg-purple-800">
            <a
              href="https://www.linkedin.com/company/symbora/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="h-5 w-5" src={Linkedin} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

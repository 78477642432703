import {
  BrowserRouter as Router,
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import Home from "./pages/home";
import Contract from "./pages/contratos/contrato";
import ContractView from "./pages/contratos/contrato/userContract";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthLayout } from "./components/AuthLayout";
import HomeLayout from "./components/HomeLayout";
import Payment from "./pages/payment";
import { Saq } from "./pages/saq";
import { Company } from "./pages/company";
import { Perfil } from "./pages/perfil";
import { Error } from "./pages/errorPage";
import { Policy } from "./pages/policy";

const getUserData = () =>
  new Promise((resolve) => {
    const user = window.localStorage.getItem("user");
    resolve(user);
  });

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos" element={<Company />} />
        <Route path="/fale-conosco" element={<Saq />} />
        <Route path="/politica-privacidade" element={<Policy />} />
        <Route
          path="/meu-perfil"
          errorElement={<Error />}
          element={
            <ProtectedRoute>
              <Perfil perfilFocus={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/meus-contratos"
          errorElement={<Error />}
          element={
            <ProtectedRoute>
              <Perfil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contrato/:idContract"
          errorElement={<Error />}
          element={<Contract />}
        />
        <Route
          path="/contrato/edit/:idContract/:idUserContract"
          errorElement={<Error />}
          element={
            <ProtectedRoute>
              <Contract currentStep={1} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contrato/view/:idUserContract"
          errorElement={<Error />}
          element={
            <ProtectedRoute>
              <ContractView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment/:idUserContract"
          errorElement={<Error />}
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>
  )
);

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div className="flex h-screen flex-col justify-between">
      <Header></Header>
      <main className="mb-auto">{children}</main>
      <Footer></Footer>
    </div>
  );
};

export default Layout;

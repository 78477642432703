const GlobalConfig = {
  http: {
    url_contract: process.env.REACT_APP_CONTRACT_URL,
    url_contract_price: process.env.REACT_APP_CONTRACT_PRICE_URL,
    url_user_contract: process.env.REACT_APP_USER_CONTRACT_URL,
    url_auth_login: process.env.REACT_APP_AUTH_LOGIN_URL,
    url_auth_register: process.env.REACT_APP_AUTH_REGISTER_URL,
    url_payment: process.env.REACT_APP_PAYMENT_URL,
    url_user: process.env.REACT_APP_USER_URL,
    url_check_status_payment: process.env.REACT_APP_CHECK_STATUS_PAYMENT_URL,
    url_auth: process.env.REACT_APP_AUTH_URL,
    url_contact: process.env.REACT_APP_CONTACT_URL,
  },
};

export default GlobalConfig;

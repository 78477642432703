import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import GreenCheck from "../../../assets/images/green-checkmark-line-icon.svg";
import RedCheck from "../../../assets/images/red-x-line-icon.svg";

const REDIRECT_TIME = 60 * 4;
const AUTOMATIC_REDIRECT = 10;

export const CreditCardModal = ({ visible, onClose, confirming }) => {
  const [countDown, setCountDown] = useState(REDIRECT_TIME);
  const [runTimer, setRunTimer] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (runTimer) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (confirming === "APROVADA") setCountDown(() => AUTOMATIC_REDIRECT);
  }, [confirming]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(REDIRECT_TIME);
      if (confirming === "APROVADA") {
        navigate("../meu-perfil");
      }
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  function handleOnClose() {
    setRunTimer(false);
    setCountDown(REDIRECT_TIME);
    if (confirming === "APROVADA") {
      navigate("../meu-perfil");
    }
    onClose();
  }

  if (!visible) return null;

  return (
    <>
      <div
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
      >
        <div className="my-6  mx-auto w-2/4 max-w-6xl sm:max-md:w-11/12">
          <div className="relative flex h-96 flex-col bg-white shadow-lg outline-none focus:outline-none">
            {confirming === "loading" ? (
              <div className="flex flex-col">
                <div className="loader m-auto mt-10"></div>
                <div className="m-4 mt-10 text-center text-[#757575]">
                  <p className="-mb-1 font-bold">
                    Aguarde estamos processando seu pagamento.
                  </p>
                </div>
                <div className="m-5">
                  <p className="font-roboto text-center text-sm text-[#757575]">
                    {" "}
                    Está operação poderá levar até{" "}
                    <span className="font-bold">
                      {minutes}:{seconds}
                    </span>
                    .
                  </p>
                </div>
              </div>
            ) : null}
            {confirming === "APROVADA" ? (
              <div className="m-auto text-center text-[#757575]">
                <div className=" m-2 flex justify-center">
                  <img src={GreenCheck} alt="" />
                </div>
                <p className="-mb-1 font-bold">Pagamento confirmado</p>
                <p className="font-bold">
                  Verifique os detalhes do seu pedido em sua caixa de email.
                </p>
                <p className="mt-5 font-bold">
                  Você será redirecionado automaticamente para a sua área de
                  contratos, caso não aconteça
                  <Link className="hover:text-[#44377C]" to="../meu-perfil">
                    {" "}
                    CLIQUE AQUI!
                  </Link>{" "}
                  .
                </p>
              </div>
            ) : null}
            {confirming === "FAIL" ? (
              <div className="m-auto text-center  text-[#757575]">
                <div className=" m-2 flex justify-center">
                  <img src={RedCheck} alt="" />
                </div>
                <p className="-mb-1 font-bold">Pagamento NÃO processado.</p>
                <p className="font-bold">
                  Verifique com a operadora do seu cartão.
                </p>
              </div>
            ) : null}
          </div>
          <div className="flex justify-center">
            <button
              className="m-2 rounded-lg bg-white py-2 px-6 font-semibold text-[#757575] hover:bg-slate-100"
              onClick={handleOnClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

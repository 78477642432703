import { useState, useEffect } from "react";

export default function Step1({ formStep, nextFormStep, inputChangeHandler }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    nextFormStep();
  };

  return (
    <div className={formStep === 0 ? "m-10 block sm:m-0" : "hidden"}>
      <div className="primary-color inline-flex w-80 pt-1 text-center text-sm font-thin text-white sm:max-md:w-full">
        <span className="mx-3 text-lg font-bold">1</span>Etapa 1 - Partes
        Contratantes
      </div>
      <form className="max-w-sm" onSubmit={handleSubmit} method="GET">
        <div className="m-4 mb-6 flex flex-col">
          <div className="">
            <label
              className="mb-2 block text-sm font-medium text-gray-900"
              htmlFor="">
              Contratante<span className="text-red-600">*</span>
            </label>
          </div>
          <div className="flex items-center">
            <select
              required
              onChange={(e) => inputChangeHandler(e)}
              className="w-full border-none border-gray-400 bg-gray-100 p-2.5 text-sm text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-700"
              name="contratante"
              id="contratante">
              <option value="">A parte quem está contratando é:</option>
              <option value="cpf">Pessoa Física (CPF)</option>
              <option value="cnpj">Pessoa Juridica (CNPJ)</option>
            </select>
            {width > 768 ? (
              <div className="group relative ml-3 inline-block cursor-pointer text-gray-600">
                <button className="rounded-2xl bg-red-50 py-0.5 px-2.5 text-sm font-semibold">
                  ?
                </button>
                <div className="absolute left-full top-1/2 z-20 ml-3 -translate-y-1/2 whitespace-nowrap rounded bg-red-50 py-[6px] px-4 text-sm font-semibold text-gray-600 opacity-0 group-hover:opacity-100">
                  <span className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-red-50"></span>
                  <p className="text-xs font-light">
                    No NDA, o Contratante é quem está revelando as informações;
                    <br></br>
                    No Contrato de Parceria, Contratante é um dos parceiros; e
                    <br></br>
                    No Contrato de Prestação de Serviços, Contratante é quem
                    contrata os serviços de outra pessoa.
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="m-4 mb-6 flex flex-col">
          <div className="">
            <label
              className="mb-2 block text-sm font-medium text-gray-900"
              htmlFor="">
              Contratada<span className="text-red-600">*</span>
            </label>
          </div>
          <div className="flex items-center">
            <select
              required
              onChange={(e) => inputChangeHandler(e)}
              className="w-full border-none border-gray-400 bg-gray-100 p-2.5 text-sm text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-700"
              name="contratada"
              id="contratada">
              <option value="">A parte contratada é:</option>
              <option value="cpf">Pessoa Física (CPF)</option>
              <option value="cnpj">Pessoa Juridica (CNPJ)</option>
            </select>
            {width > 768 ? (
              <div className="group relative ml-3 cursor-pointer text-gray-600">
                <button className="rounded-2xl bg-red-50 py-0.5 px-2.5 text-sm font-semibold">
                  ?
                </button>
                <div className="absolute left-full top-1/2 z-20 ml-3 -translate-y-1/2 whitespace-nowrap rounded bg-red-50 py-[6px] px-4 text-sm font-semibold text-gray-600 opacity-0 group-hover:opacity-100">
                  <span className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-red-50 sm:max-md:w-full"></span>
                  <p className="text-xs font-light">
                    No NDA, o Contratado é o receptor das informações reveladas
                    pelo Contratante
                    <br></br>
                    No Contrato de Parceria, Contratado é o parceiro do
                    Contratante; e<br></br>
                    No Contrato de Prestação de Serviços, Contratado é quem
                    presta os serviços ao Contratante.
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-3/3 flex justify-end text-white">
          <button
            className="primary-color focus:shadow-outline m-4 rounded-md py-1 px-6 hover:bg-purple-900 focus:outline-none"
            type="submit"
            id="nextBtn">
            Próxima
          </button>
        </div>
      </form>
    </div>
  );
}

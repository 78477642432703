import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  useFetchContractInitial,
  sendPayment,
  useFetchUserContract,
  useFetchCheckPayment,
} from "../../api";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PixModal } from "../../components/modals/modalPix";
import { CreditCardModal } from "../../components/modals/ModalCreditCard";
import { LoadingModal } from "../../components/modals/ModalLoading";
import InputMask from "react-input-mask";
import { validateCPF } from "../../utils/validateCPF";
import { validateCNPJ } from "../../utils/validateCNPJ";
import { useNavigate } from "react-router-dom";

export default function Payment() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [modal, setShowModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [paymentLoading, setCreditCardConfirmed] = useState("");
  const [pixLoading, setPixConfirmed] = useState("none");
  const [qrCode, setQRCode] = useState("");
  const [qrcodeHash, setQRCodeHash] = useState("");
  const id = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const userContractQuery = useFetchUserContract(user.id, id.idUserContract);
  let statusPayment = useFetchCheckPayment(id.idUserContract);
  let contractQuery = useFetchContractInitial(
    userContractQuery.data?.contrato_id,
    false
  );
  let formValues = watch();
  const ERROR_MESSAGE = "Por favor, preencha este campo obrigatório.";
  const navigate = useNavigate();

  if (userContractQuery.data?.contrato_id) {
    if (!contractQuery.data) contractQuery.refetch();
  }

  const handleCep = async () => {
    const url_cep = `https://cdn.apicep.com/file/apicep/${formValues.customer.address.postal_code}.json`;
    await axios.get(url_cep).then((res) => {
      formValues.customer.address.street = res.data.address;
      document.getElementById("street").value =
        formValues.customer.address.street;
      formValues.customer.address.city = res.data.city;
      document.getElementById("city").value = formValues.customer.address.city;
      formValues.customer.address.neighborhood = res.data.district;
      document.getElementById("neighborhood").value =
        formValues.customer.address.neighborhood;
      formValues.customer.address.state = res.data.state;
      document.getElementById("state").value =
        formValues.customer.address.state;
    });
  };

  let title = contractQuery?.data?.titulo;
  const index = contractQuery?.data?.titulo?.indexOf("(");
  if (index >= 0) {
    title = title.substring(0, index - 1);
  }

  const showModal = (currentModal) => {
    switch (currentModal) {
      case "PIX":
        return (
          <PixModal
            confirming={pixLoading}
            title={title}
            svgPix={qrCode}
            price={Number(contractQuery.data?.preco)}
            qrcodeHash={qrcodeHash}
            visible={modal}
            onClose={setShowModal}
          />
        );
      case "CARTAO_CREDITO":
        return (
          <CreditCardModal
            confirming={paymentLoading}
            payment_method={formValues.payment.payment_method}
            visible={modal}
            onClose={setShowModal}
          />
        );
      default:
        break;
    }
  };

  const cep = register("customer.address.postal_code", {
    required: "Código postal é obrigatório.",
    pattern: /[0-9]{5}-[0-9]{3}/,
    onBlur: handleCep,
  });
  const creditCardNumber = register("payment.card_number", {
    required:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? true : false,
    disabled:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? false : true,
  });
  const expDate = register("payment.card_expiration", {
    required:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? true : false,
    disabled:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? false : true,
  });
  const cardCvv = register("payment.card_cvv", {
    required:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? true : false,
    pattern: /[0-9]{3}/,
    disabled:
      formValues.payment?.payment_method === "CARTAO_CREDITO" ? false : true,
  });
  const contactNumber = register("customer.contacts.number_contact", {
    required: "Número é obrigatório.",
  });
  const cnpj = register("customer.cnpj", {
    pattern: /\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}/,
    disabled: formValues.pessoa_tipo === "fisica" ? true : false,
    validate: { notValid: (v) => validateCNPJ(v) === true },
  });
  const cpf = register("customer.cpf", {
    pattern:
      /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
    validate: { notValid: (v) => validateCPF(v) === true },
  });
  const birthDate = register("customer.birth_date", {
    required: formValues.pessoa_tipo === "fisica" ? true : false,
    pattern: /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
  });

  const onSubmit = async (data) => {
    try {
      setLoadingButton(true);
      setShowModal(true);
      setCreditCardConfirmed("loading");
      if (data.payment.payment_method === "CARTAO_CREDITO") {
        setShowModal(true);
        setCurrentModal("CARTAO_CREDITO");
      }

      if (data.customer.cnpj) {
        data.customer.trade_name = data.customer.company_name;
      }

      data.customer.cnpj = data.customer.cnpj?.replace(/[^a-zA-Z0-9 ]/g, "");
      data.customer.cpf = data.customer.cpf?.replace(/[^a-zA-Z0-9 ]/g, "");
      data.payment.card_number = data.payment.card_number?.replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
      data.customer.contacts.number_contact =
        data.customer.contacts.number_contact.replace(/([^\d])+/gim, "");
      data.customer.address.postal_code =
        data.customer.address.postal_code.replace("-", "");
      data.customer.id = Number(user.id);
      data.payment.split = "1";
      if (data.payment.card_expiration) {
        const [month, year] = data.payment.card_expiration.split("/");
        delete data.payment.card_expiration;
        data.payment.card_expdate_month = month;
        data.payment.card_expdate_year = `20${year}`;
      }
      const input = {
        ...data,
        transaction_product: {
          description: title,
          quantity: 1,
          price_unit: Number(contractQuery.data.preco),
          code: Number(id.idUserContract),
          contract_id: Number(userContractQuery.data?.contrato_id),
          extra: "Opcional - informações adicionais",
        },
        transaction: {
          customer_ip: "127.0.0.1",
        },
      };

      const response = await sendPayment(input);
      if (response === "Accepted") {
        if (input.payment.payment_method === "CARTAO_CREDITO") {
          const payment = await statusPayment.refetch();
          if (payment.data?.status === "APROVADA") {
            setCreditCardConfirmed("APROVADA");
          } else {
            setCreditCardConfirmed("FAIL");
          }
        }
      }
      if (input.payment.payment_method === "PIX") {
        setQRCode(response.qrcode_path);
        setQRCodeHash(response.qrcode_original_path);
        setCurrentModal("PIX");
        setShowModal(true);
        const payment = await statusPayment.refetch();
        if (payment.data?.status === "APROVADA") {
          setPixConfirmed("APROVADA");
        }
      }
      setLoadingButton(false);
    } catch (error) {
      console.log(error);
      setCreditCardConfirmed("FAIL");
      setLoadingButton(false);
    }
  };

  return (
    <div className="text-primary-color mt-10 flex flex-col justify-center sm:flex-col md:flex-row">
      {showModal(currentModal)}
      <div className="mx-10 mt-5 p-10 sm:max-md:mx-0 sm:max-md:p-2 lg:w-1/3">
        <p className="text-5xl font-thin tracking-wider">
          <strong className="font-bold">SEU</strong> DOCUMENTO ESTÁ PRONTO!
        </p>
        <p className="">
          A Symbora se preocupa com a segurança da informação, adotando as
          melhores práticas e políticas de proteção de dados, em cumprimento à
          LGPD. Visando à segurança de dados, a Symbora utiliza os serviços da
          Amazon Web Services - AWS e a certificação PCI-DSS.
        </p>
      </div>
      <div className="m-6 w-fit p-4 shadow-2xl sm:m-0 sm:w-full sm:p-1">
        <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-1">
            <div className="flex sm:max-md:flex-col">
              <div className="w-2/3 sm:max-md:w-full">
                <p className="mx-10 text-xl">
                  Contrato: <strong>{title}</strong>
                </p>
              </div>
              <div className="ml-16">
                <p className="text-primary-color text-xl">
                  R${contractQuery?.data?.preco}
                </p>
              </div>
            </div>
            <p className="mx-10 -mt-2 text-xl">
              Documento criado no formato PDF
            </p>
          </div>
          <div className="mx-5 flex flex-col">
            <label
              className="text-primary-color mx-6 my-2 font-semibold "
              htmlFor="">
              Melhor email para receber a confirmação de compra
              <span className="">*</span>
            </label>
            <input
              className="input-form input-payment mx-6 rounded-sm"
              type="text"
              name="customer.email"
              id="customer.email"
              {...register("customer.email", {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            <ErrorMessage
              errors={errors}
              name="customer.email"
              render={({ message }) => (
                <p className="mx-4 p-1 text-xs text-red-500">
                  Por valor preencha com email válido.
                </p>
              )}
            />
            <p className=" mx-6">
              Os dados abaixo são para fins de emissão de Nota Fiscal
            </p>
          </div>
          <div className="mx-11 pt-5">
            <div className="flex gap-1 sm:max-md:flex-col">
              <div>
                <input
                  className="h-3 w-3 rounded-full p-1 checked:appearance-none checked:bg-black"
                  type="radio"
                  name="pessoa_tipo"
                  id=""
                  value="fisica"
                  {...register("pessoa_tipo", {
                    required: true,
                    onChange: () => setValue("customer.cnpj", null),
                  })}
                />
                <label className="mr-5 p-1 text-xl" htmlFor="">
                  Preencher com CPF
                </label>
              </div>
              <div>
                <input
                  className="h-3 w-3 rounded-full p-1 checked:appearance-none checked:bg-black"
                  type="radio"
                  name="pessoa_tipo"
                  id=""
                  value="juridica"
                  {...register("pessoa_tipo", {
                    required: true,
                    onChange: () => setValue("customer.cpf", null),
                  })}
                />
                <label className="p-2 text-xl" htmlFor="">
                  Preencher com CNPJ
                </label>
              </div>
            </div>
          </div>
          {formValues.pessoa_tipo === "fisica" ? (
            <div className="mx-6 mr-16 flex flex-col">
              <label
                className="text-primary-color mx-5 my-2 font-semibold"
                htmlFor="">
                Nome<span className="">*</span>
              </label>
              <input
                className="input-form input-payment w-full rounded-sm"
                name="customer.name"
                id="customer.name"
                type="text"
                {...register("customer.name", {
                  required: "Nome é obrigatório.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="customer.name"
                render={({ message }) => (
                  <p className="mx-4 p-1 text-xs text-red-500">
                    {ERROR_MESSAGE}
                  </p>
                )}
              />
            </div>
          ) : null}
          {formValues.pessoa_tipo === "juridica" ? (
            <>
              <div className="mx-6 mr-16 flex flex-col">
                <label
                  className="text-primary-color mx-5 my-2 font-semibold"
                  htmlFor="">
                  Razão social<span className="">*</span>
                </label>
                <input
                  className="input-form input-payment w-full rounded-sm"
                  name="company_name"
                  id="company_name"
                  type="text"
                  {...register("customer.company_name", {
                    required: "Razão social é obrigatório.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="customer.company_name"
                  render={({ message }) => (
                    <p className="mx-4 p-1 text-xs text-red-500">
                      {ERROR_MESSAGE}
                    </p>
                  )}
                />
              </div>
            </>
          ) : null}
          <div className="flex sm:max-md:flex-col">
            <div className="mx-4">
              <div className="text-primary-color flex sm:max-md:flex-col">
                <>
                  <div className="flex w-72 flex-col p-2">
                    <label
                      className="text-primary-color mx-5 my-2 font-semibold"
                      htmlFor="">
                      CPF do comprador<span className="">*</span>
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      className="input-form input-payment rounded-sm disabled:opacity-75"
                      name={cpf.name}
                      inputRef={cpf.ref}
                      onChange={cpf.onChange}
                      onBlur={cpf.onBlur}
                    />

                    {errors?.customer?.cpf?.type === "notValid" ? (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        Por favor, informe um CPF válido
                      </p>
                    ) : null}
                    {errors?.customer?.cpf?.type === "pattern" ? (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        Por favor, preencha este campo obrigatório.
                      </p>
                    ) : null}
                  </div>
                </>
                {formValues.pessoa_tipo === "juridica" ? (
                  <>
                    <div className="flex w-72 flex-col p-2">
                      <label
                        className="text-primary-color mx-5 my-2 font-semibold"
                        htmlFor="">
                        CNPJ<span className=""> *</span>
                      </label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        className="input-form input-payment rounded-sm disabled:opacity-75"
                        name={cnpj.name}
                        inputRef={cnpj.ref}
                        onChange={cnpj.onChange}
                        onBlur={cnpj.onBlur}
                      />
                      {errors?.customer?.cnpj?.type === "notValid" ? (
                        <p className="mx-4 p-1 text-xs text-red-500">
                          Por favor, informe um CNPJ válido
                        </p>
                      ) : null}
                      {errors?.customer?.cnpj?.type === "pattern" ? (
                        <p className="mx-4 p-1 text-xs text-red-500">
                          Por favor, preencha este campo obrigatório.
                        </p>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div className="flex w-64 flex-col p-2">
                  <label className="mx-5 my-2 font-semibold" htmlFor="">
                    Número para contato<span className="">*</span>
                  </label>
                  <InputMask
                    mask="(99) 99999-9999"
                    className="input-form input-payment rounded-sm"
                    name={contactNumber.name}
                    inputRef={contactNumber.ref}
                    onChange={contactNumber.onChange}
                    onBlur={contactNumber.onBlur}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="customer.contacts.number_contact"
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        {ERROR_MESSAGE}
                      </p>
                    )}
                  />
                </div>
              </div>
              {formValues.pessoa_tipo === "fisica" ? (
                <>
                  <div className="flex w-56 flex-col p-2">
                    <label
                      className="text-primary-color  mx-5 my-2 font-semibold"
                      htmlFor="">
                      Data de Nascimento<span className="">*</span>
                    </label>
                    <InputMask
                      mask="99/99/9999"
                      placeholder="Ex: 30/12/1990"
                      className="input-form input-payment w-full rounded-sm"
                      name={birthDate.name}
                      inputRef={birthDate.ref}
                      onChange={birthDate.onChange}
                      onBlur={birthDate.onBlur}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="customer.birth_date"
                      render={({ message }) => (
                        <p className="mx-4 p-1 text-xs text-red-500">
                          {ERROR_MESSAGE}
                        </p>
                      )}
                    />
                  </div>
                </>
              ) : null}
              <div className="flex sm:max-md:flex-col">
                <div className="flex w-56 flex-col p-2">
                  <label
                    className="text-primary-color  mx-5 my-2 font-semibold"
                    htmlFor="">
                    CEP<span className="">*</span>
                  </label>
                  <InputMask
                    mask="99999-999"
                    inputRef={cep.ref}
                    className="input-form input-payment w-full rounded-sm"
                    placeholder="Ex: 0000-000"
                    name={cep.name}
                    onChange={cep.onChange}
                    onBlur={cep.onBlur}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="customer.address.postal_code"
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        {ERROR_MESSAGE}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="flex sm:max-md:flex-col">
                <div className="flex w-full flex-col p-2">
                  <label className="mx-5  my-2 font-semibold" htmlFor="">
                    Endereço<span className="">*</span>
                  </label>
                  <input
                    className="input-form input-payment rounded-sm"
                    placeholder="Digite o seu endereço ou informe o CEP"
                    type="text"
                    name="street"
                    id="street"
                    {...register("customer.address.street", {
                      required: "Endereço é obrigatório.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="customer.address.street"
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        {ERROR_MESSAGE}
                      </p>
                    )}
                  />
                </div>
                <div className="flex w-32 flex-col p-2">
                  <label
                    className="text-primary-color  mx-5 my-2 font-semibold"
                    htmlFor="">
                    Número<span className="">*</span>
                  </label>
                  <input
                    className="input-form input-payment rounded-sm"
                    type="text"
                    name="numero"
                    id="number"
                    {...register("customer.address.number", {
                      required: "Número é obrigatório.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="customer.address.number"
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        {ERROR_MESSAGE}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="flex w-36 flex-col p-2 sm:max-md:w-2/3">
                <label
                  className="text-primary-color  mx-5 my-2 font-semibold"
                  htmlFor="">
                  Complemento
                </label>
                <input
                  className="input-form input-payment w-full rounded-sm"
                  placeholder="(Opcional)"
                  type="text"
                  name="customer.address.completion"
                  id=""
                  {...register("customer.address.completion")}
                />
              </div>

              <div className="flex sm:max-md:flex-col">
                <div className="flex w-1/3 flex-col p-2 sm:max-md:w-2/3">
                  <label
                    className="text-primary-color mx-5 my-2 font-semibold"
                    htmlFor="">
                    Bairro<span className="">*</span>
                  </label>
                  <input
                    className="input-form input-payment rounded-sm"
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    {...register("customer.address.neighborhood", {
                      required: "Bairro é obrigatório.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="customer.address.neighborhood"
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">
                        {ERROR_MESSAGE}
                      </p>
                    )}
                  />
                </div>
                <div className="flex sm:max-md:flex-row">
                  <div className="flex w-1/3 flex-col p-2 sm:max-md:w-2/3">
                    <label
                      className="text-primary-color mx-5 my-2 font-semibold"
                      htmlFor="">
                      Cidade<span className="">*</span>
                    </label>
                    <input
                      className="input-form input-payment rounded-sm"
                      type="text"
                      name="city"
                      id="city"
                      {...register("customer.address.city", {
                        required: "Cidade é obrigatório.",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="customer.address.city"
                      render={({ message }) => (
                        <p className="mx-4 p-1 text-xs text-red-500">
                          {ERROR_MESSAGE}
                        </p>
                      )}
                    />
                  </div>
                  <div className="flex w-1/3 flex-col p-2">
                    <label
                      className="text-primary-color mx-5 my-2 font-semibold"
                      htmlFor="">
                      Estado<span className="">*</span>
                    </label>
                    <input
                      className="input-form input-payment rounded-sm"
                      type="text"
                      name="state"
                      id="state"
                      {...register("customer.address.state", {
                        required: "Estado é obrigatório.",
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="customer.address.state"
                      render={({ message }) => (
                        <p className="mx-4 p-1 text-xs text-red-500">
                          {ERROR_MESSAGE}
                        </p>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="mx-7 pt-5 ">
                <label className="text-primary-color font-semibold" htmlFor="">
                  Forma de pagamento:<span className="">*</span>
                </label>
                <div className="mt-2">
                  <input
                    className="h-3 w-3 rounded-full p-1 checked:appearance-none checked:bg-black"
                    type="radio"
                    name="payment.payment_method"
                    id=""
                    value="CARTAO_CREDITO"
                    {...register("payment.payment_method", { required: true })}
                  />
                  <label className="mr-5 p-1 text-xl" htmlFor="">
                    Crédito
                  </label>
                  <input
                    className="ml-5 h-3 w-3 rounded-full p-1 checked:appearance-none checked:bg-black"
                    type="radio"
                    name="payment.payment_method"
                    id=""
                    value="PIX"
                    {...register("payment.payment_method", { required: true })}
                  />
                  <label className="p-2 text-xl" htmlFor="">
                    Pix
                  </label>
                </div>
              </div>
              {formValues.payment?.payment_method === "CARTAO_CREDITO" ? (
                <>
                  <div className="flex sm:max-md:flex-col">
                    <div className="flex w-2/3 flex-col p-2 sm:max-md:w-full">
                      <label className="my-2 mx-5 font-semibold" htmlFor="">
                        Número do Cartão<span className="">*</span>
                      </label>
                      <InputMask
                        inputRef={creditCardNumber.ref}
                        mask="9999-9999-9999-9999"
                        className="input-form input-payment w-full rounded-sm"
                        name={creditCardNumber.name}
                        onChange={creditCardNumber.onChange}
                        onBlur={creditCardNumber.onBlur}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="payment.card_number"
                        render={({ message }) => (
                          <p className="mx-4 p-1 text-xs text-red-500">
                            {ERROR_MESSAGE}
                          </p>
                        )}
                      />
                    </div>
                    <div className="flex w-1/3 flex-col p-2 sm:max-md:w-2/4">
                      <label className="my-2 mx-5 font-semibold" htmlFor="">
                        Validade<span className="">*</span>
                      </label>
                      <InputMask
                        mask="99/99"
                        placeholder="Ex: 12/26"
                        className="input-form input-payment rounded-sm"
                        name={expDate.name}
                        inputRef={expDate.ref}
                        onChange={expDate.onChange}
                        onBlur={expDate.onBlur}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="payment.card_expiration"
                        render={({ message }) => (
                          <p className="mx-4 p-1 text-xs text-red-500">
                            {ERROR_MESSAGE}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 sm:max-md:flex-col">
                    <div className="flex w-2/3 flex-col p-2 sm:max-md:w-full">
                      <label
                        className="text-primary-color mx-5 my-2 font-semibold"
                        htmlFor="">
                        Nome do Títular do Cartão<span className="">*</span>
                      </label>
                      <input
                        className="input-form input-payment w-full rounded-sm"
                        type="text"
                        name="payment.card_name"
                        id=""
                        {...register("payment.card_name", {
                          required: "Nome do titular é obrigatório.",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="payment.card_name"
                        render={({ message }) => (
                          <p className="mx-4 p-1 text-xs text-red-500">
                            {ERROR_MESSAGE}
                          </p>
                        )}
                      />
                    </div>
                    <div className="flex flex-col p-2">
                      <label
                        className="text-primary-color mx-5 my-2 font-semibold "
                        htmlFor="">
                        Código de Segurança<span className="">*</span>
                      </label>
                      <InputMask
                        mask="999"
                        placeholder="Ex: 456"
                        className="input-form  input-payment w-1/3 rounded-sm"
                        inputRef={cardCvv.ref}
                        name={cardCvv.name}
                        onChange={cardCvv.onChange}
                        onBlur={cardCvv.onBlur}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="payment.card_cvv"
                        render={({ message }) => (
                          <p className="mx-4 p-1 text-xs text-red-500">
                            {ERROR_MESSAGE}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="mx-7 my-6">
                <input
                  type="checkbox"
                  name="accept_conditions"
                  id=""
                  {...register("accept_conditions", {
                    required: "É necessário aceitar as condições de venda.",
                  })}
                />
                <label className="text-primary-color mx-2" htmlFor="">
                  Eu aceito as Condições Gerais de Venda.
                </label>
                <ErrorMessage
                  errors={errors}
                  name="accept_conditions"
                  render={({ message }) => (
                    <p className="mx-4 p-1 text-xs text-red-500">
                      {ERROR_MESSAGE}
                    </p>
                  )}
                />
              </div>
              <div className="mx-5">
                <button
                  className="primary-color m-2 rounded-md p-2 font-semibold text-white hover:bg-purple-900"
                  disabled={isLoadingButton}>
                  Finalizar Pedido
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import GreenCheck from "../../../assets/images/green-checkmark-line-icon.svg";

export const ConfirmModal = ({ message, visible, onClose }) => {
  const handleOnClose = (e) => {
    onClose(false);
  };

  if (!visible) return null;

  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
      >
        <div className="max-w-6xl">
          <div className="flex flex-row bg-white shadow-lg outline-none focus:outline-none">
            <div className="mx-20 my-16 w-full">
              <div className="m-auto text-center text-[#757575]">
                <div className=" m-2 flex justify-center">
                  <img src={GreenCheck} alt="" />
                </div>
                <p className="-mb-1 font-bold">{message}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="m-2 rounded-lg bg-white py-2 px-6 font-semibold text-[#757575] hover:bg-slate-100"
              onClick={handleOnClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

import Facebook from "../../assets/images/icons/facebookFooter.svg";
import Instagram from "../../assets/images/icons/instagramFooter.svg";
import Linkedin from "../../assets/images/icons/linkedinFooter.svg";
import WhatsApp from "../../assets/images/icons/whatsappFooter.svg";
import SymboraBranco from "../../assets/images/symboraBranco.svg";

import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="primary-color">
      <div className="text-center">
        <div className="flex flex-col items-center">
          <img
            className="m-4 w-56 p-4 "
            src={SymboraBranco}
            alt="logo no rodape"
          />
          <div className="mb-8 flex">
            <a
              className="mx-2"
              href="https://www.facebook.com/symboraempreender"
              target="_blank"
              rel="noopener noreferrer">
              <img className="" src={Facebook} alt="" />
            </a>
            <a
              className="mx-2"
              href="https://www.instagram.com/symbora_empreender/"
              target="_blank"
              rel="noopener noreferrer">
              <img className="" src={Instagram} alt="" />
            </a>
            <a
              className="mx-2"
              href="https://www.linkedin.com/company/symbora/"
              target="_blank"
              rel="noopener noreferrer">
              <img className="" src={Linkedin} alt="" />
            </a>
            <a
              className="mx-2"
              href="https://api.whatsapp.com/send?phone=11989056171"
              target="_blank"
              rel="noopener noreferrer">
              <img className="" src={WhatsApp} alt="" />
            </a>
          </div>
        </div>
        <div className="flex justify-center text-slate-300">
          <div className="px-2">
            <Link to="/politica-privacidade">TERMOS DE USO E PRIVACIDADE</Link>
          </div>
          <div className="px-2">
            <Link to="/fale-conosco">FALE CONOSCO</Link>
          </div>
        </div>
        <div className="pb-8 pt-4 text-xs font-light text-slate-300 underline">
          COPYRIGHT SYMBORA 2023
        </div>
      </div>
    </footer>
  );
}

import MulherCaneta from "../../assets/images/mulherCaneta.svg";
import Contrato from "../../assets/images/contrato.svg";
import Cloud from "../../assets/images/icons/cloud.svg";
import LeftArrow from "../../assets/images/icons/leftArrow.svg";
import RightArrow from "../../assets/images/icons/rightArrow.svg";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { RegisterModal } from "../../components/modals/ModalRegister";
import { CarrocelCard } from "../../components/carrocelCard";
import { useFetchContractPrice } from "../../api";
import { Carousel } from "flowbite-react";
import { useEffect } from "react";

export default function Home() {
  const { user } = useAuth();
  const [modal, setShowModal] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const NDA = 17;
  const SERVICO = 13;
  const PARCERIA = 28;

  const contractQueryNDA = useFetchContractPrice(NDA);
  const contractQuerySERVICO = useFetchContractPrice(SERVICO);
  const contractQueryPARCERIA = useFetchContractPrice(PARCERIA);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <main className="">
        <div className="flex items-center justify-center p-6 md:flex-row lg:flex-row">
          <div className="lg:m-auto lg:w-1/2 ">
            <p className="text-primary-color font-semibold sm:text-3xl md:text-4xl lg:text-6xl">
              O seu contrato em menos de 10 minutos!
            </p>
          </div>
          <div className="m-auto lg:scale-75">
            <img className="-z-10" src={Contrato} alt="" />
          </div>
        </div>

        <div className="relative">
          <div className="flex h-64 justify-center bg-gradient-to-b from-[#44377C] to-[#7C6BCB] p-2">
            {windowDimensions.width <= 1024 ? (
                <Carousel indicators={false}>
                  <CarrocelCard
                    title="NDA"
                    subtitle="Acordo de Confidencialidade"
                    url={`contrato/${contractQueryNDA?.data?.id}`}
                    price={contractQueryNDA?.data?.preco}
                  />
                  <CarrocelCard
                    data-carousel-item
                    title="Contrato de"
                    subtitle="Prestação de Serviços"
                    url={`contrato/${contractQuerySERVICO?.data?.id}`}
                    price={contractQuerySERVICO?.data?.preco}
                  />
                  <CarrocelCard
                    data-carousel-item
                    title="Contrato de"
                    subtitle="Parceria"
                    url={`contrato/${contractQueryPARCERIA?.data?.id}`}
                    price={contractQueryPARCERIA?.data?.preco}
                  />
                </Carousel>
            ) : (
              <div className="flex items-end">
                <CarrocelCard
                  title="NDA"
                  subtitle="Acordo de Confidencialidade"
                  url={`contrato/${contractQueryNDA?.data?.id}`}
                  price={contractQueryNDA?.data?.preco}
                />
                <CarrocelCard
                  data-carousel-item
                  title="Contrato de"
                  subtitle="Prestação de Serviços"
                  url={`contrato/${contractQuerySERVICO?.data?.id}`}
                  price={contractQuerySERVICO?.data?.preco}
                />
                <CarrocelCard
                  data-carousel-item
                  title="Contrato de"
                  subtitle="Parceria"
                  url={`contrato/${contractQueryPARCERIA?.data?.id}`}
                  price={contractQueryPARCERIA?.data?.preco}
                />
              </div>
            )}
          </div>
        </div>

        <div className="sm:w-full md:m-auto md:mt-12 md:text-center lg:m-auto lg:w-2/3">
          <p className="text-primary-color sm:text-center sm:text-xs md:text-center md:text-base lg:text-left lg:text-base">
            Contratos sob medida para empreendedores digitais e todo o
            ecossistema digital: criadores de conteúdos, lançadores, gestores de
            mídias sociais, copywriters, filmmakers, bem como todos os
            prestadores de serviços que trabalham, ou desejam trabalhar com
            negócios digitais.
          </p>
        </div>
        <div className="text-primary-color flex justify-center sm:flex-col md:flex-col  lg:my-28 lg:flex-row">
          <div className=" md:my-5 md:text-center lg:w-1/2 lg:text-left">
            <p className="font-semibold sm:text-center sm:text-2xl md:text-center md:text-5xl lg:text-left lg:text-6xl">
              Em apenas três passos você formaliza o seu contrato!
            </p>
            <p className="sm:text-center  md:text-center lg:text-left lg:text-2xl">
              Segurança jurídica e rapidez. O seu contrato elaborado por
              especialistas fica salvo na nuvem, disponível para uso sempre que
              precisar
            </p>
          </div>
          <div className="p-auto flex flex-col justify-center">
            <div className="mx-2 my-4 flex items-start">
              <img
                className="px-4 sm:scale-75 md:scale-90 lg:scale-100"
                src={Cloud}
                alt=""
              />
              <p className="items-center font-bold sm:text-xl md:text-xl lg:text-2xl">
                Você edita aqui na plataforma.
              </p>
            </div>
            <div className="mx-2 my-4 flex items-start">
              <img
                className="px-4 sm:scale-75 md:scale-90 lg:scale-100"
                src={Cloud}
                alt=""
              />
              <p className="font-bold sm:text-xl md:text-xl lg:text-2xl">
                Baixa
              </p>
            </div>
            <div className="mx-2 my-4 flex items-start">
              <img
                className="px-4 sm:scale-75 md:scale-90 lg:scale-100"
                src={Cloud}
                alt=""
              />
              <p className="font-bold sm:text-xl md:text-xl lg:text-2xl">
                Envia o contrato para assinatura!
              </p>
            </div>
          </div>
        </div>
        <div className="m-auto flex items-center sm:flex-col  md:flex-col lg:my-28  lg:flex-row">
          <div className="">
            <img className="object-scale-down" src={MulherCaneta} alt="" />
          </div>
          <div className="text-primary-color md:mx-16 md:text-center lg:mr-auto lg:w-1/2">
            <p className="p-2 font-semibold sm:text-center sm:text-2xl md:text-center md:text-5xl lg:text-right lg:text-6xl  ">
              Não faça acordos sem validade jurídica. Se proteja, nunca foi tão
              fácil dar segurança ao seu negócio.
            </p>
            <p className="p-2 text-xl  sm:text-center md:text-center lg:text-right">
              Basta inserir algumas informações básicas do tipo de contrato que
              você deseja, e em menos de 10 minutos você tem o seu contrato
              pronto para assinatura.
            </p>
            <p className="-mt-4 p-2 text-xl sm:text-center md:text-center lg:text-right">
              Quem disse que empreender com segurança jurídica precisa ser caro
              e difícil?{" "}
            </p>
          </div>
        </div>
      </main>
      <RegisterModal visible={modal} onClose={setShowModal} />
    </>
  );
}

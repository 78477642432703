import { useForm } from "react-hook-form";
import { updatePassword } from "../../../api";
import Login from "../../../assets/images/loginPhoto.jpg";
import { useState } from "react";

export const PasswordModal = ({ toogleForm, visible, onClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { erros },
  } = useForm();
  const [isLocked, setLocked] = useState(false);
  const [isRegistered, setIsRegistered] = useState(true);

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };

  const onSubmit = async (data) => {
    setLocked(true);

    console.log(data);
    const res = await updatePassword(data);
    if (res === "Email não cadastrado") {
      setIsRegistered(false);
    } else {
      onClose();
    }
    setLocked(false);
    console.log(res);
  };

  if (!visible) return null;

  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto overflow-y-auto outline-none focus:outline-none"
      >
        <div className="">
          <div className="flex bg-white shadow-lg outline-none focus:outline-none">
            <div className="w-full p-20 sm:p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 text-2xl font-bold underline">
                  <p className="">Recuperação de senha</p>
                </div>
                <div className="font-mulish flex flex-col">
                  <label className="-mx-1 px-6 py-3">
                    Email<span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 sm:w-3/4 border-none text-sm focus:outline-none"
                    type="text"
                    {...register("email", { required: true })}
                  />
                  <p className="mx-3 px-2 pt-1 text-xs">
                    Você receberá um email para cadastro de uma nova senha.
                  </p>
                  {!isRegistered ? (
                    <p className="mx-3 px-2 text-xs  text-red-600">
                      Email não cadastrado.
                    </p>
                  ) : null}
                </div>
                <div className="mt-8 ml-5 flex w-full flex-col">
                  <button
                    className="primary-color mb-6 w-96 sm:w-3/4 rounded-md p-2 text-xl text-white hover:bg-purple-900"
                    disabled={isLocked}
                  >
                    ENVIAR
                  </button>
                  <button
                    className="flex justify-start text-sm underline"
                    onClick={() => toogleForm("login")}
                  >
                    Logar com email e senha
                  </button>
                </div>
              </form>
            </div>
            <div className="px-6 block sm:hidden">
              <img
                src={Login}
                className="h-full object-cover"
                alt="mulher digitando algo no notebook"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

import { useAuth } from "../../hooks/useAuth";
import { Navigate, useOutlet } from "react-router-dom";
import Layout from "../layout";
import "@fontsource/open-sans";
import "@fontsource/mulish";

export default function HomeLayout() {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    //return <Navigate to="/" replace />;
  }

  return <Layout>{outlet}</Layout>;
}

import Login from "../../../assets/images/loginPhoto.jpg";
import { useForm } from "react-hook-form";
import axios from "axios";
import GlobalConfig from "../../../GlobalConfig";
import { useAuth } from "../../../hooks/useAuth";
import { submitOnEnter } from "../../../utils/submitKeyPress";
import { useState } from "react";

export const LoginModal = ({ toogleForm, visible, onClose }) => {
  const {
    http: { url_auth_login },
  } = GlobalConfig;
  const {
    register,
    handleSubmit,
    formState: { erros },
  } = useForm();
  const [error, setError] = useState(null);
  const { login } = useAuth();

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };

  async function loginData(data) {
    return await axios
      .post(url_auth_login, data)
      .then((response) => response.data);
  }

  const onSubmit = async (data) => {
    const response = await loginData({
      email: data.email.trim(),
      password: data.password,
    });
    if (response.token) {
      const res = await login({ ...response });

      onClose();
    } else {
      setError(response);
    }
  };

  if (!visible) return null;
  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="max-w-6xl">
          <div className="flex bg-white shadow-lg outline-none focus:outline-none">
            <div className="m-20 p-20 sm:max-md:m-2 sm:max-md:p-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="-mx-1 w-full px-4">
                  <p className="text-2xl font-bold underline">Login</p>
                </div>
                <div className="-mx-1 px-6 py-2">
                  <button
                    className="underline"
                    onClick={() => toogleForm("register")}>
                    Clique aqui e crie a sua conta
                  </button>
                </div>
                <div className="font-mulish my-2 flex flex-col">
                  <label className="-mx-1 px-6 py-3 text-[#333333]">
                    Entrar com seu email<span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 border-none sm:max-md:w-3/4"
                    type="text"
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="font-mulish my-2 flex flex-col">
                  <label className="-mx-1 px-6 py-3 text-[#333333]">
                    Senha<span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 border-none focus:outline-none sm:max-md:w-3/4"
                    onKeyDown={(e) => submitOnEnter(e, "submitButton")}
                    type="password"
                    {...register("password", { required: true })}
                  />
                  {error ? (
                    <p className="mx-5 my-1 text-red-600">{error}</p>
                  ) : null}
                </div>

                <div className="mx-4 mt-8 flex justify-start text-center">
                  <button
                    id="submitButton"
                    className="primary-color mb-6 w-96 rounded-md p-2  text-xl text-white hover:bg-purple-900 sm:max-md:w-3/4"
                    type="submit">
                    ENTRAR
                  </button>
                </div>
              </form>
              <div className="mx-4">
                <button onClick={() => toogleForm("password")} className="">
                  Esqueceu sua senha?
                </button>
              </div>
            </div>
            <div className="px-6 sm:max-md:hidden md:block">
              <img
                src={Login}
                className="h-full object-cover"
                alt="mulher digitando algo no notebook"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

export const snakeToCamel = (str) =>
  str.toLowerCase().charAt(0).toUpperCase() +
  str
    .slice(1)
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const camelToSnake = (key) => {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

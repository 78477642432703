function hasAllDigitsEqual(cpf) {
  const [firstDigit] = cpf;
  return cpf.split("").every((digit) => digit === firstDigit);
}

function extractDigit(cpf) {
  return cpf.slice(9);
}

function calculateDigit(cpf, factor) {
  let total = 0;
  for (const digit of cpf) {
    if (factor > 1) total += parseInt(digit) * factor--;
  }
  const rest = total % 11;
  return rest < 2 ? 0 : 11 - rest;
}
function cleanCpf(cpf) {
  return cpf.replace(/\D/g, "");
}

export function validateCPF(cpf) {
  if (!cpf || cpf.length === 0) {
    return false;
  }
  cpf = cleanCpf(cpf);
  if (hasAllDigitsEqual(cpf)) return false;
  const digit1 = calculateDigit(cpf, 10);
  const digit2 = calculateDigit(cpf, 11);
  const checkDigit = extractDigit(cpf);
  const calculatedDigit = `${digit1}${digit2}`;
  return checkDigit == calculatedDigit;
}

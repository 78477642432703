import Partnership from "../../assets/images/partnership.svg";
import "@fontsource/montserrat";
import { CarrocelCard } from "../../components/carrocelCard";
import { useFetchContractPrice } from "../../api";
import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";

export const Company = () => {
  const NDA = 17;
  const SERVICO = 13;
  const PARCERIA = 28;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const contractQueryNDA = useFetchContractPrice(NDA);
  const contractQuerySERVICO = useFetchContractPrice(SERVICO);
  const contractQueryPARCERIA = useFetchContractPrice(PARCERIA);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="flex h-64 justify-center bg-gradient-to-b from-[#44377C] to-[#7C6BCB] p-2">
        {windowDimensions.width <= 1024 ? (
          <Carousel className="w-2/3" indicators={false}>
            <CarrocelCard
              title="NDA"
              subtitle="Acordo de Confidencialidade"
              url={`../contrato/${contractQueryNDA?.data?.id}`}
              price={contractQueryNDA?.data?.preco}
            />
            <CarrocelCard
              data-carousel-item
              title="Contrato de"
              subtitle="Prestação de Serviços"
              url={`../contrato/${contractQuerySERVICO?.data?.id}`}
              price={contractQuerySERVICO?.data?.preco}
            />
            <CarrocelCard
              data-carousel-item
              title="Contrato de"
              subtitle="Parceria"
              url={`../contrato/${contractQueryPARCERIA?.data?.id}`}
              price={contractQueryPARCERIA?.data?.preco}
            />
          </Carousel>
        ) : (
          <div className="flex items-end">
            <CarrocelCard
              title="NDA"
              subtitle="Acordo de Confidencialidade"
              url={`../contrato/${contractQueryNDA?.data?.id}`}
              price={contractQueryNDA?.data?.preco}
            />
            <CarrocelCard
              data-carousel-item
              title="Contrato de"
              subtitle="Prestação de Serviços"
              url={`../contrato/${contractQuerySERVICO?.data?.id}`}
              price={contractQuerySERVICO?.data?.preco}
            />
            <CarrocelCard
              data-carousel-item
              title="Contrato de"
              subtitle="Parceria"
              url={`../contrato/${contractQueryPARCERIA?.data?.id}`}
              price={contractQueryPARCERIA?.data?.preco}
            />
          </div>
        )}
      </div>
      <div className="mx-10 flex  justify-center sm:flex-col-reverse md:flex-col-reverse lg:flex-row">
        <div className="md:justify-center lg:w-1/2">
          <img className="text-center lg:my-24" src={Partnership} alt="" />
        </div>
        <div className="flex flex-col p-2 lg:mx-10 lg:my-24 lg:w-1/2">
          <h1 className="mb-3 p-2 text-5xl font-bold md:text-center lg:text-left">
            A SYMBORA
          </h1>
          <p className="text-2xl sm:text-center md:text-center lg:w-2/3 lg:text-left ">
            A Symbora foi pensada e construída para facilitar a vida dos
            empreendedores digitais e de todo o ecossistema digital,
            principalmente dos criadores de conteúdos, dos lançadores, dos
            gestores de mídias sociais, dos copywriters, dos filmmakers, assim
            como de todos os prestadores de serviços, que já trabalham ou que
            desejam trabalhar com negócios digitais. A nossa missão é
            racionalizar toda a parte burocrática do negócio digital,
            economizando os escassos recursos dos empreendedores digitais e dos
            seus prestadores de serviços! Com a Symbora, você poderá reduzir
            drasticamente os riscos jurídicos do seu negócio, liberando o seu
            precioso tempo para as atividades relevantes. A Symbora veio para
            ajudar a alcançar os seus objetivos e realizar o seu propósito! Os
            empreendedores digitais e os prestadores de serviços, que atuam no
            ecossistema digital, poderão obter na Symbora os documentos
            essenciais para o sucesso do seu negócio, de forma segura, e com a
            melhor relação custo/benefício do mercado!
          </p>
          <p className="mt-8 text-2xl md:text-center lg:w-2/3 lg:text-left">
            SYMBORA EMPREENDER ONLINE?!
          </p>
        </div>
      </div>
      <div className="my-10 mx-10">
        <p className="font-montserrat text-center text-5xl">
          A Symbora devolve o seu tempo e te dá segurança jurídica para que você
          possa focar no seu negócio
        </p>

        <p className="text-center text-2xl">
          Encontre os contratos essenciais para o seu negócio aqui e use o seu
          tempo para desenvolver a sua empresa{" "}
        </p>
      </div>
    </div>
  );
};

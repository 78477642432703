import GlobalConfig from "../GlobalConfig";
import axios from "axios";
import { useQuery } from "react-query";

const {
  http: {
    url_contract,
    url_user_contract,
    url_contract_price,
    url_payment,
    url_user,
    url_check_status_payment,
    url_auth,
    url_contact,
  },
} = GlobalConfig;

const contract = {
  17: {
    cpf: {
      cpf: 17,
      cnpj: 18,
    },
    cnpj: {
      cpf: 19,
      cnpj: 20,
    },
  },
  13: {
    cpf: {
      cpf: 13,
      cnpj: 14,
    },
    cnpj: {
      cpf: 15,
      cnpj: 16,
    },
  },
  28: {
    cpf: {
      cpf: 58,
      cnpj: 59,
    },
    cnpj: {
      cnpj: 28,
      cpf: 57,
    },
  },
};

export const fetchContractinitial = async (idContract) => {
  const { data } = await axios.get(`${url_contract}${idContract}`);
  return data;
};

export const useFetchContractInitial = (idContract, enable) => {
  const contractQuery = useQuery({
    queryKey: ["contractInitial", idContract],
    staleTime: 1000 * 60 * 10,
    cacheTime: Infinity,
    enabled: enable,
    queryFn: () => fetchContractinitial(idContract),
  });
  return contractQuery;
};

export const fetchUserPerfil = async (userId) => {
  const data = await axios
    .get(`${url_user}/${userId}/perfil`)
    .then((response) => response.data)
    .catch((error) =>
      error.response.status == 404 ? {} : console.log(error.response)
    );
  return data;
};

export const savePerfil = async (userId, data) => {
  const res = await axios
    .post(`${url_user}/${userId}/perfil`, data)
    .then((res) => res.data)
    .catch((error) => console.log(error));
  return res;
};

export const savePerfilPhoto = async (userId, data) => {
  const res = await axios
    .put(`${url_user}/${userId}/perfil/photo`, data)
    .then((res) => res.data)
    .catch((error) => console.log(error));
  return res;
};

export const updateEmail = async (userId, data) => {
  const res = await axios
    .put(`${url_user}/${userId}/change-email`, data)
    .then((res) => res.data)
    .catch((error) => {
      error.message = "Erro ao atualizar email";
      return error;
    });
  return res;
};

export const updatePassword = async (data) => {
  const res = await axios
    .post(`${url_user}/passwordRecovery`, data)
    .then((res) => console.log(res))
    .catch((error) => {
      console.log(error);
      return error;
    });
  return res;
};
export const updateNickname = async (data) => {
  const { res } = await axios.put(`${url_contract}nickname`, data);

  return res;
};

export const changePassword = async (input) => {
  const { data } = await axios.put(
    `${url_auth}/${input.user_id}/change-password`,
    input
  );
  return data;
};

export const sendContact = async (email) => {
  const { data } = await axios.post(url_contact, email);
  return email;
};

export const fetchContract = async (idContract, formStep1) => {
  let data;
  if (formStep1?.contratante && formStep1?.contratada) {
    data = await axios.get(
      `${url_contract}${contract[idContract][formStep1.contratante][formStep1.contratada]
      }`
    );
  } else {
    data = await axios.get(`${url_contract}${idContract}`);
  }
  return data.data;
};

export const fetchContractPrice = async (idContract) => {
  const { data } = await axios.get(`${url_contract_price}${idContract}`);
  return data;
};

export const fetchUserContract = async (userId, idUserContract) => {
  if (!idUserContract) return null;
  const { data } = await axios.get(`${url_user_contract}`, {
    params: { usuario_id: userId, id: idUserContract },
  });
  return data;
};

export const fetchUserContractPreview = async (userId, idUserContract) => {
  if (!idUserContract) return null;
  const { data } = await axios.get(
    `${url_user_contract}${idUserContract}/user/${userId}/preview`
  );
  return data;
};

export const fetchAllUserContracts = async (userId) => {
  const { data } = await axios.get(`${url_user_contract}`, {
    params: { usuario_id: userId },
  });
  return data;
};

export const saveContract = async (input) => {
  const { data } = await axios.post(url_contract, input);
  return data;
};

export const updateContract = async (input) => {
  const { data } = await axios.put(url_contract, input);
  return data;
};

export const sendPayment = async (input) => {
  const { data } = await axios.post(url_payment, input);
  return data;
};

export const checkPayment = async (userContractId) => {
  const { data } = await axios.get(
    `${url_check_status_payment}/${userContractId}`
  );
  if (data.status != "APROVADA") throw new Error(data.status);
  return data;
};

export const useFetchCheckPayment = (userContractId) => {
  const contractQuery = useQuery({
    queryKey: ["checkPayment", userContractId],
    staleTime: 0,
    enabled: false,
    retry: 15,
    retryDelay: 14000,
    queryFn: () => checkPayment(userContractId),
  });
  return contractQuery;
};

export const useFetchContractPrice = (idContract) => {
  const contractQuery = useQuery({
    queryKey: ["contractPrice", idContract],
    staleTime: 1000 * 60 * 20,
    cacheTime: 1000 * 60 * 20,
    queryFn: () => fetchContractPrice(idContract),
  });
  return contractQuery;
};

export const useFetchContract = (idContract, formStep1, enabled = true) => {
  const contractQuery = useQuery({
    queryKey: ["contract", idContract, formStep1],
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 5,
    enabled: enabled,
    queryFn: () => fetchContract(idContract, formStep1),
  });
  return contractQuery;
};

export const useFetchUserContract = (userId, idUserContract = null) => {
  const userContractQuery = useQuery({
    queryKey: ["userContract", userId, idUserContract],
    staleTime: 0,
    cacheTime: 0,
    queryFn: () => fetchUserContract(userId, idUserContract),
  });
  return userContractQuery;
};

export const useFetchUserContractPreview = (userId, idUserContract = null) => {
  const userContractQuery = useQuery({
    queryKey: ["userContractPreview", userId, idUserContract],
    staleTime: 0,
    cacheTime: 0,
    queryFn: () => fetchUserContractPreview(userId, idUserContract),
  });
  return userContractQuery;
};

export const useAllFetchUserContracts = (userId) => {
  const userContractQuery = useQuery({
    queryKey: ["allUserContracts", userId],
    staleTime: 0,
    cacheTime: 0,
    queryFn: () => fetchAllUserContracts(userId),
  });
  return userContractQuery;
};

export const useFetchUserPerfil = (userId) => {
  return useQuery({
    queryKey: ["fetchUserPerfil", userId],
    staleTime: 0,
    cacheTime: 0,
    queryFn: () => fetchUserPerfil(userId),
  });
};

import { Link } from "react-router-dom";

export const CarrocelCard = ({ title, subtitle, url, price }) => {
  return (
    <div className="flex justify-center">
      <div className="w-2/3 hover:text-primary-color delay-50 hover:salmon-quare group flex justify-center rounded-lg text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-125 hover:shadow-lg">
        <div className="yellow delay-50 group-hover:primary-color my-14  ml-4 h-20 w-0.5 transition duration-300 ease-in-out"></div>
        <div className="m-2 flex flex-col  items-center px-4 py-8 text-center ">
          <span className="text-2xl font-bold">{title}</span>
          <p className="w-full text-2xl font-bold">{subtitle}</p>
          <div className="flex w-56">
            <Link className="button-contract p-2" to={url}>
              <button className="">OBTER CONTRATO R${price}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

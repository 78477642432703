import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { RegisterModal } from "../modals/ModalRegister";
import { LoginModal } from "../modals/ModalLogin";
import { PasswordModal } from "../modals/ModalPassword";
import SymboraLogo from "../../assets/images/symboraLogo.svg";
import { useAuth } from "../../hooks/useAuth";
import ArrowDown from "../../assets/images/chevron-down-solid.svg";
import { Menu, Transition } from "@headlessui/react";

export default function Header() {
  const [isOpen, setOpen] = useState(true);
  const [modal, setShowModal] = useState(false);
  const [currentForm, setCurrentForm] = useState("register");
  const { logout, user } = useAuth();

  const toogleForm = (formName) => {
    setCurrentForm(formName);
  };

  function showModal(currentForm) {
    switch (currentForm) {
      case "register":
        return (
          <RegisterModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );

      case "login":
        return (
          <LoginModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      case "password":
        return (
          <PasswordModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      default:
        break;
    }
  }
  if (user) {
    return (
      <div className="px-2">
        <header className="bg-white px-2 py-3">
          <div className="flex items-center sm:justify-between sm:p-0 md:justify-between lg:justify-around">
            <div>
              <Link to="/">
                <img
                  src={SymboraLogo}
                  className="sm:ml-3 md:ml-6"
                  alt="Symbora Logo"
                />
              </Link>
            </div>
            <div>
              <Menu>
                <Menu.Button
                  onClick={() => setOpen(!isOpen)}
                  type="button"
                  className={
                    isOpen
                      ? "inline-flex items-end rounded-lg p-1 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100 md:hidden"
                      : "inline-flex items-end rounded-lg p-1 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100"
                  }
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="h-7 w-7"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {isOpen ? (
                      <path
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    ) : (
                      <>
                        <svg
                          className="h-8 w-8 justify-end text-gray-600"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </>
                    )}
                  </svg>
                </Menu.Button>
              </Menu>
            </div>
            {isOpen ? (
              <div className="text-primary-color hidden items-center md:flex">
                <Link
                  to={`/quem-somos`}
                  className="block rounded  px-4 text-xl font-semibold hover:bg-gray-100"
                >
                  Quem somos
                </Link>
                <Link
                  to={`/fale-conosco`}
                  className="block rounded  px-4 text-xl font-semibold hover:bg-gray-100"
                >
                  Fale conosco
                </Link>
                <Menu as="div" className="">
                  {({ open }) => (
                    <Fragment>
                      <Menu.Button className="inline-flex w-full justify-center rounded px-4 text-xl font-semibold hover:bg-gray-100 focus:outline-none">
                        Meu Perfil{" "}
                        <img
                          className="ml-2 -mr-1 h-6 w-3"
                          src={ArrowDown}
                          alt=""
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transform transition duration-100 ease-in"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transform transition duration-75 ease-out"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items className="fixed right-4 z-50 w-48 rounded-lg bg-white p-2 text-right text-gray-600 shadow-xl focus:outline-none">
                          <div className="rounded-lg p-2 hover:bg-gray-100">
                            <Menu.Item>
                              <Link to="/meus-contratos">Meus Contratos</Link>
                            </Menu.Item>
                          </div>
                          <div className="rounded-lg p-2 hover:bg-gray-100">
                            <Menu.Item>
                              <Link to="/meu-perfil">Editar Perfil</Link>
                            </Menu.Item>
                          </div>
                          <div className="rounded-lg p-2 hover:bg-gray-100">
                            <Menu.Item>
                              <button className="" onClick={() => logout()}>
                                Sair
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Fragment>
                  )}
                </Menu>
              </div>
            ) : null}
          </div>
          {!isOpen ? (
            <div className="text-primary-color flex flex-col items-end px-4 pt-2 pb-4">
              <Link
                to="/quem-somos"
                className="block rounded px-2  py-1 font-semibold hover:bg-gray-100"
              >
                Quem somos
              </Link>
              <Link
                to="/fale-conosco"
                className="mt-1 block rounded px-2  py-1 font-semibold hover:bg-gray-100"
              >
                Fale conosco
              </Link>
              <Menu as="div" className="relative mt-1 py-1 px-2 ">
                {({ open }) => (
                  <Fragment>
                    <Menu.Button className="ml-4 inline-flex rounded font-semibold hover:bg-gray-100 focus:outline-none">
                      Meu Perfil{" "}
                      <img
                        className="ml-2 -mr-1 h-6 w-3"
                        src={ArrowDown}
                        alt=""
                      />
                    </Menu.Button>
                    <Transition
                      show={open}
                      enter="transform transition duration-100 ease-in"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transform transition duration-75 ease-out"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items className=" z-30 text-right text-gray-600 focus:outline-none">
                        <div className="rounded-lg p-1 hover:bg-gray-100">
                          <Menu.Item>
                            <Link to="/meus-contratos" className="">
                              Meus Contratos
                            </Link>
                          </Menu.Item>
                        </div>
                        <div className="rounded-lg p-1 hover:bg-gray-100">
                          <Menu.Item>
                            <Link to="/meu-perfil" className="">
                              Editar Perfil
                            </Link>
                          </Menu.Item>
                        </div>
                        <div className="rounded-lg p-1 hover:bg-gray-100">
                          <Menu.Item>
                            <button onClick={() => logout()}>Sair</button>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Fragment>
                )}
              </Menu>
            </div>
          ) : null}
        </header>
      </div>
    );
  }

  return (
    <div className="px-2">
      <header className="bg-white px-2 py-3">
        <div className="flex items-center sm:justify-between sm:p-0  md:justify-between lg:justify-around ">
          <div>
            <Link to="/">
              <img
                src={SymboraLogo}
                className="sm:ml-3 md:ml-6"
                alt="Symbora Logo"
              />
            </Link>
          </div>
          <div>
            <button
              onClick={() => setOpen(!isOpen)}
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center rounded-lg p-1 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100 md:hidden "
              aria-controls="navbar-sticky"
              aria-expanded="true"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-7 w-7"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                ) : (
                  <>
                    <svg
                      className="h-8 w-8 justify-end text-gray-600"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </>
                )}
              </svg>
            </button>
          </div>
          {isOpen ? (
            <div className="text-primary-color hidden  items-center md:flex">
              <Link
                to={`/quem-somos`}
                className="block rounded  px-4 text-xl font-semibold hover:bg-gray-100"
              >
                Quem somos
              </Link>
              <Link
                to={`/fale-conosco`}
                className="block rounded  px-4 text-xl font-semibold hover:bg-gray-100"
              >
                Fale conosco
              </Link>
              <button
                type="button"
                className="block rounded  px-4 text-xl font-bold hover:bg-gray-100"
                onClick={() => setShowModal(true)}
              >
                LOGIN
              </button>
            </div>
          ) : null}
        </div>
        {!isOpen ? (
          <div className="text-primary-color flex flex-col items-end px-4 pt-2 pb-4">
            <Link
              to="/quem-somos"
              className="block rounded px-2  py-1 font-semibold hover:bg-gray-100"
            >
              Quem somos
            </Link>
            <Link
              to="/fale-conosco"
              className="mt-1 block rounded px-2  py-1 font-semibold hover:bg-gray-100"
            >
              Fale conosco
            </Link>
            <button
              className="mt-1 block rounded px-2  py-1 font-bold hover:bg-gray-100"
              onClick={() => setShowModal(true)}
            >
              LOGIN
            </button>
            {/* <button
              className="mt-1 block rounded px-2 py-1 text-xl font-bold hover:bg-gray-100"
              onClick={() => logout()}
            >
              Sair
            </button> */}
          </div>
        ) : null}

        {showModal(currentForm)}
      </header>
    </div>
  );
}

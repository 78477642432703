import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { router } from "./App";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
const root = ReactDOM.createRoot(document.getElementById("root"));
import { ReactQueryDevtools } from "react-query/devtools";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MQCH57P'
}

TagManager.initialize(tagManagerArgs)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <RouterProvider router={router} />
    <ReactQueryDevtools initialIsOpen={true} />
  </QueryClientProvider>
);

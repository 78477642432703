import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchUserContractPreview } from "../../../../api";
import { Link } from "react-router-dom";
import { LoginModal } from "../../../../components/modals/ModalLogin";
import { RegisterModal } from "../../../../components/modals/ModalRegister";
import { PasswordModal } from "../../../../components/modals/ModalPassword";

export default function ContractView({ currentStep = 0 }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [currentForm, setCurrentForm] = useState("login");
  const [modal, setShowModal] = useState(false);
  const id = useParams();
  console.log(user);
  const userContractPreviewQuery = useFetchUserContractPreview(
    user.id,
    id.idUserContract
  );

  console.log(userContractPreviewQuery);
  useEffect(() => {
    if (currentStep === 1) {
      nextFormStep();
    }
  }, []);

  const toogleForm = (formName) => {
    setCurrentForm(formName);
  };

  function showModal(currentForm) {
    switch (currentForm) {
      case "register":
        return (
          <RegisterModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );

      case "login":
        return (
          <LoginModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      case "password":
        return (
          <PasswordModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      default:
        break;
    }
  }

  function nextFormStep() {
    if (!user) {
      setShowModal(true);
      showModal(currentForm);
    } else {
      userContractQuery.refetch({ throwOnError: true });
      setWizardStep((currentStep) => currentStep + 1);
    }
  }

  if (userContractPreviewQuery?.isLoading) {
    return (
      <section className="m-10 p-28 text-center text-4xl font-bold">
        Carregando...
      </section>
    );
  }

  return (
    <>
      <a
        download="contrato.pdf"
        href={`data:application/pdf;base64,${userContractPreviewQuery?.data?.pdf}`}>
        <button className="primary-color mx-5 my-8 rounded-lg px-6 py-2 font-bold text-white hover:bg-purple-900">
          Download do Contrato
        </button>
      </a>
      <Link
        to="/meus-contratos"
        className="primary-color mx-5 my-8 rounded-lg px-6 py-2 font-bold text-white hover:bg-purple-900">
        Voltar
      </Link>

      <div id="pdf" className="page prevent-select overflow-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: userContractPreviewQuery?.data?.template,
          }}></div>
      </div>
    </>
  );
}

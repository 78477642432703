import { snakeToCamel } from "../../utils/convertString";
import { useNavigate } from "react-router-dom";
import {
  useFetchContract,
  saveContract,
  useFetchUserContract,
  updateContract,
} from "../../api";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ProgressBar from "../progressBar";

export default function Step2({
  idContract,
  idUserContract,
  prevFormStep,
  formStep1,
  setIsFocused = false,
}) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext();
  let userContractQuery;
  let contractQuery;
  let dataTemplateWithValues = [];

  const contract = {
    17: {
      cpf: {
        cpf: 17,
        cnpj: 18,
      },
      cnpj: {
        cpf: 19,
        cnpj: 20,
      },
    },
    13: {
      cpf: {
        cpf: 13,
        cnpj: 14,
      },
      cnpj: {
        cpf: 15,
        cnpj: 16,
      },
    },
    28: {
      cpf: {
        cpf: 58,
        cnpj: 59,
      },
      cnpj: {
        cnpj: 28,
        cpf: 57,
      },
    },
  };

  userContractQuery = useFetchUserContract(user.id, idUserContract);
  contractQuery = useFetchContract(idContract, formStep1);

  const mappingEditValues = () => {
    for (const iterator of contractQuery.data.template) {
      for (const iterator2 of Object.keys(userContractQuery.data.dados)) {
        if (snakeToCamel(iterator.campo) === snakeToCamel(iterator2)) {
          dataTemplateWithValues.push({
            ...iterator,
            value: userContractQuery.data.dados[iterator2],
          });
        }
      }
    }
  };

  if (contractQuery.isLoading || userContractQuery.isLoading) {
    return (
      <section className="m-10 p-28 text-center text-4xl font-bold">
        Carregando...
      </section>
    );
  }
  if (!contractQuery.isLoading && !userContractQuery.isLoading) {
    if (userContractQuery.data) mappingEditValues();
  }

  const onSubmit = async (data) => {
    const contractId = userContractQuery.data?.contrato_id;
    let sendData = {};
    let idFromSaveContract;
    if (!idUserContract) {
      sendData = {
        dados: data,
        contrato_id:
          contract[idContract][formStep1.contratante][formStep1.contratada],
        usuario_id: user.id,
      };
      idFromSaveContract = await saveContract(sendData);
    } else {
      sendData = {
        id: idUserContract,
        dados: data,
        contrato_id: contractId,
        usuario_id: user.id,
      };
      await updateContract(sendData);
    }
    //generatePDF();
    if (idFromSaveContract) {
      navigate(`../payment/${idFromSaveContract.id}`);
    } else {
      navigate(`../meu-perfil/`);
    }
  };

  if (contractQuery?.isLoading) {
    return (
      <section className="p-28 text-center text-3xl font-bold">
        Carregando...
      </section>
    );
  }
  if (contractQuery?.isError) {
    return (
      <section className="p-28 text-center text-3xl font-bold">
        Desculpe não conseguimos encontrar o seu contrato.
      </section>
    );
  }

  const formulario = watch();
  const formSize = Object.keys(formulario).length;
  const values = Object.values(formulario);
  let filledCounter = 0;
  for (const iterator of values) {
    if (iterator.length != 0) {
      filledCounter++;
    }
  }

  const percentage = Math.round((filledCounter / formSize) * 100);

  //const formKeys = Object.keys(formulario);
  // if (formKeys[0]) {
  //     document.getElementById(formKeys[0]).focus();

  // }

  return (
    <div className="p-10 text-white ">
      <div>
        <span className="mx-5 text-sm text-black">
          {percentage ? `${percentage}%` : "0%"}
        </span>
        <ProgressBar completed={percentage} bgcolor="#D6DC40" />
      </div>

      <div className="primary-color inline-flex w-80 pt-1 text-center text-sm font-thin sm:max-md:w-full">
        <span className="mx-3 text-lg font-bold">2</span> Etapa 2 -
        Preenchimento do contrato
      </div>
      <form className="h-96 overflow-auto">
        {dataTemplateWithValues.length > 0
          ? dataTemplateWithValues.map((input) => {
              const campo = snakeToCamel(input.campo);
              return (
                <div className="flex flex-col" key={campo}>
                  <label className="label-form" htmlFor={campo}>
                    {input.configs.descricao}
                  </label>
                  <input
                    className={
                      errors[campo]
                        ? `border-1 input-form border-red-400 focus:outline-none`
                        : `input-form border-none`
                    }
                    type="text"
                    placeholder={input.configs.placeholder}
                    defaultValue={input.campo}
                    autoFocus={true}
                    name={campo}
                    onFocus={() => setIsFocused({ [campo]: true })}
                    onBlur={() => setIsFocused({ [campo]: false })}
                    {...register(campo, {
                      required:
                        input.configs.obrigatorio == 1
                          ? "Por favor, preencha este campo obrigatório"
                          : false,
                      value: input.value,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={snakeToCamel(input.campo)}
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">{message}</p>
                    )}
                  />
                </div>
              );
            })
          : contractQuery?.data.template.map((input) => {
              const campo = snakeToCamel(input.campo);
              return (
                <div className="flex flex-col" key={campo}>
                  <label className="label-form" htmlFor={campo}>
                    {input.configs.descricao}
                  </label>
                  <input
                    id={campo}
                    className={
                      errors[campo]
                        ? `border-1 input-form border-red-400  focus:outline-none`
                        : `input-form border-none`
                    }
                    type="text"
                    placeholder={input.configs.placeholder}
                    defaultValue=""
                    name={campo}
                    onFocus={() => setIsFocused({ [campo]: true })}
                    onBlur={() => setIsFocused({ [campo]: false })}
                    {...register(campo, {
                      required:
                        input.configs.obrigatorio == 1
                          ? "Por favor, preencha este campo obrigatório"
                          : false,
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={campo}
                    render={({ message }) => (
                      <p className="mx-4 p-1 text-xs text-red-500">{message}</p>
                    )}
                  />
                </div>
              );
            })}
      </form>
      <div className="md:w-3/3 flex justify-end">
        <button
          className="primary-color focus:shadow-outline m-4 rounded-md py-1 px-6 hover:bg-purple-900 focus:outline-none"
          type="button"
          id="prevBtn"
          hidden={idUserContract ? true : false}
          onClick={prevFormStep}>
          Anterior
        </button>
        <button
          className="primary-color focus:shadow-outline m-4 rounded-md py-1 px-6 hover:bg-purple-900 focus:outline-none"
          type="submit"
          id="nextBtn"
          onClick={handleSubmit(onSubmit)}>
          Finalizar
        </button>
      </div>
    </div>
  );
}

import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useAllFetchUserContracts,
  useFetchUserPerfil,
  savePerfil,
  savePerfilPhoto,
  updateEmail,
  changePassword,
} from "../../api";
import { submitOnEnter } from "../../utils/submitKeyPress";
import { Link } from "react-router-dom";
import CamPhotoIcon from "../../assets/images/icons/camAddPhoto.svg";
import { NicknameModal } from "../../components/modals/ModalNickname";
import InputMask from "react-input-mask";
import axios from "axios";
import { ConfirmModal } from "../../components/modals/ModalConfirm";
import { useRef } from "react";

export const Perfil = ({ perfilFocus = false }) => {
  const { id } = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const userPerfil = useFetchUserPerfil(id);
  const allUserContracsQuery = useAllFetchUserContracts(id);
  const {
    register,
    handleSubmit,
    watch,
    formState: { erros },
  } = useForm();
  const [contractId, setContractId] = useState();
  const [message, setMessage] = useState();
  const [modal, setShowModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [differentPass, setDifferentPassword] = useState(null);
  let formValues = watch();

  // console.log(perfilFocus);
  // const nameFocus = useCallback((inputElement) => {
  //     console.log(inputElement);
  //     if (inputElement) {
  //         inputElement.focus();
  //     }
  // }, [perfilFocus]);

  const nameInput = useRef(null);
  useEffect(() => {
    console.log(nameInput);
    if (nameInput.current) {
      console.log(aqui);
      nameInput.current.focus();
    }
  }, [perfilFocus]);

  const {
    register: registerEmail,
    formState: { errors: errorsEmail },
    handleSubmit: handleSubmitEmail,
  } = useForm();

  const {
    register: registerChangePassword,
    formState: { errors: errorsChangePassword },
    handleSubmit: handleSubmitUpdatePassword,
  } = useForm();

  const [userPhoto, setUserPhoto] = useState({
    file: null,
    imagePreviewUrl: userPerfil.data?.foto
      ? `data:${userPerfil.data?.foto?.mime};base64,${userPerfil.data?.foto.file}`
      : CamPhotoIcon,
  });

  // userPhoto.imagePreviewUrl =
  useEffect(() => {
    if (!userPerfil.isLoading && userPerfil.data?.foto) {
      setUserPhoto({
        file: null,
        imagePreviewUrl: `data:${userPerfil.data?.foto?.mime};base64,${userPerfil.data?.foto?.file}`,
      });
    }
  }, [userPerfil.data?.foto]);

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUserPhoto({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap sm:max-md:img-wrap-mobile img-upload flex items-center justify-center">
        <img className="object-fit " htmlFor="photo-upload" src={src} />
      </div>
      <input
        id="photo-upload"
        type="file"
        onChange={onChange}
        accept="image/png, image/jpeg"
      />
    </label>
  );

  const formatData = (data) => {

    if (!data) return "";
    data = new Date(data);
    return data.toLocaleDateString();
  };

  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCep = async () => {
    const url_cep = `https://cdn.apicep.com/file/apicep/${formValues.address.postal_code}.json`;
    await axios.get(url_cep).then((res) => {
      console.log(res);
      formValues.address.street = res.data.address;
      document.getElementById("street").value = formValues.address.street;
      formValues.address.city = res.data.city;
      document.getElementById("city").value = formValues.address.city;
      formValues.address.neighborhood = res.data.district;
      document.getElementById("neighborhood").value =
        formValues.address.neighborhood;
      formValues.address.state = res.data.state;
      document.getElementById("state").value = formValues.address.state;
    });
  };

  const cep = register("address.postal_code", {
    pattern: /[0-9]{5}-[0-9]{3}/,
    onBlur: handleCep,
    value: userPerfil.data?.address?.postal_code,
  });

  const onSubmit = async (data) => {
    data.nome_fantasia = data.razao_social;
    data.address.number = Number(data.address.number);

    data.address.postal_code = data.address.postal_code.replace("-", "");
    if (userPhoto?.file) {
      const file = await convertToBase64(userPhoto.file);

      const photo = {
        filename: userPhoto.file.name,
        base64: file.split(",")[1],
        contentType: userPhoto.file.type,
      };

      await savePerfilPhoto(id, photo);
    }

    await savePerfil(id, data);
    setMessage("Alterações salvas com sucesso.");
    setShowModal(false);
    setCurrentModal("UpdateConfirm");
    setShowModal(true);
    navigate("/meu-perfil");
  };

  const onSubmitUpdateEmail = async (data) => {
    const res = await updateEmail(id, data);

    if (res.message) {
      alert(res.message);
    } else {
      setMessage("Email atualizado com sucesso.");
      setShowModal(false);
      setCurrentModal("UpdateConfirm");
      setShowModal(true);
      navigate("/meu-perfil");
    }
  };

  const onSubmitUpdatePassword = async (data) => {
    if (data.new_password != data.new_password_confirm)
      setDifferentPassword("senhas diferentes.");

    const res = await changePassword({
      password: data.new_password,
      user_id: id,
    });

    if (res.message) {
      alert("A senha não pode ser atualizada");
    } else {
      setMessage("A senha foi atualizada com sucesso.");
      setShowModal(false);
      setCurrentModal("UpdateConfirm");
      setShowModal(true);
      navigate("/meu-perfil");
      // navigate('/meu-perfil')
    }
  };

  const refetchContract = () => {
    allUserContracsQuery.refetch();
  };
  // console.log(perfilFocus);
  // const input = document.getElementById("name");
  // useEffect(() => {
  //     // console.log(nameRef.current);
  //     // nameRef.current = focus()

  //     if (perfilFocus) {
  //         input?.focus()
  //     }

  // }, [perfilFocus])

  const showModal = (currentModal) => {
    switch (currentModal) {
      case "UpdateConfirm":
        return (
          <ConfirmModal
            message={message}
            visible={modal}
            onClose={setShowModal}
          />
        );
      case "NickName":
        return (
          <>
            {modal ? (
              <NicknameModal
                visible={modal}
                handleReload={refetchContract}
                idContract={contractId}
                onClose={setShowModal}
              />
            ) : null}
          </>
        );
      default:
        break;
    }
  };

  if (allUserContracsQuery.isLoading) {
    return (
      <section className="p-28 text-center text-3xl font-bold">
        Carregando...
      </section>
    );
  }

  if (userPerfil.isLoading) {
    return (
      <section className="p-28 text-center text-3xl font-bold">
        Carregando...
      </section>
    );
  }

  return (
    <div>
      {showModal(currentModal)}
      <div className="my-20 flex justify-center sm:flex-col sm:max-md:justify-items-center md:flex-col lg:flex-row">
        <div className="w-fit sm:max-md:w-full">
          <div className="mx-10 px-4">
            <p className="my-4 ml-2 text-5xl font-bold">PERFIL</p>
            <p className="my-4 text-xl">
              Complete seu perfil para ter conteúdos e contratos de acordo com
              seu perfil!
            </p>
          </div>
          <div className="mx-10 px-6">
            <p className="font m-auto my-2 text-xl">Meus contratos</p>
            <ul>
              {Object.values(allUserContracsQuery?.data).map((contract) => (
                <li
                  className="my-2 flex sm:flex-col md:flex-col lg:flex-row"
                  key={contract.id}>
                  <div className="w-56">
                    <Link
                      to={`../contrato/view/${contract.id}`}
                      className="mr-2 min-w-fit underline hover:text-xl">
                      {contract.apelido
                        ? contract.apelido
                        : `Meu contrato PDF - ${formatData(
                            contract.updated_at
                          )}`}
                    </Link>
                  </div>
                  <div>
                    <button
                      className="m-2 bg-[#8DC74A] px-4 py-1 text-white hover:bg-[#D6DC40]"
                      onClick={() => {
                        setContractId(contract.id),
                          setCurrentModal("NickName"),
                          setShowModal(true);
                      }}>
                      Renomear
                    </button>
                    <Link
                      to={`../contrato/edit/${contract.contrato_id}/${contract.id}`}
                      className="underline ">
                      <button className="m-2 bg-[#8DC74A] px-4 py-1 text-white hover:bg-[#D6DC40]">
                        Editar contrato
                      </button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-fit shadow-lg sm:max-md:w-full ">
          <div className="m-5">
            <ImgUpload
              style=""
              onChange={photoUpload}
              src={userPhoto.imagePreviewUrl}
            />
          </div>
          <div className="m-5 ">
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Nome:
                </label>
                <input
                  className="input-form border-none"
                  id="name"
                  type="text"
                  name=""
                  ref={nameInput}
                  {...register("name", {
                    required: false,
                    value: userPerfil.data?.name,
                  })}
                />
              </div>
              <div className="flex w-64 flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Telefone:
                </label>
                <input
                  className="input-form border-none"
                  type="text"
                  name=""
                  id=""
                  {...register("number_contact", {
                    required: false,
                    value: userPerfil.data?.number_contact,
                  })}
                />
              </div>
              <div className="flex">
                <div className="flex w-1/4 flex-col sm:max-md:w-3/4">
                  <label className="mx-5 my-3" htmlFor="">
                    CEP:
                  </label>
                  <InputMask
                    mask="99999-999"
                    inputRef={cep.ref}
                    className="input-form border-none"
                    placeholder="Ex: 0000-000"
                    name={cep.name}
                    onChange={cep.onChange}
                    onBlur={cep.onBlur}
                  />
                </div>
              </div>
              <div className="flex sm:max-md:flex-col">
                <div className="flex w-full flex-col">
                  <label className="mx-5 my-3" htmlFor="">
                    Endereço:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id="street"
                    {...register("address.street", {
                      required: false,
                      value: userPerfil.data?.address?.street,
                    })}
                  />
                </div>
                <div className="flex w-32 flex-col">
                  <label className="mx-5 my-3" htmlFor="">
                    Número:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id=""
                    {...register("address.number", {
                      required: false,
                      value: userPerfil.data?.address?.number,
                    })}
                  />
                </div>
                <div className="flex w-36 flex-col sm:max-md:w-3/4">
                  <label className="mx-5 my-3" htmlFor="">
                    Complemento:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id="completion"
                    {...register("address.completion", {
                      required: false,
                      value: userPerfil.data?.address?.completion,
                    })}
                  />
                </div>
              </div>
              <div className="flex sm:max-md:flex-col">
                <div className="flex w-2/4 flex-col sm:max-md:w-3/4">
                  <label className="mx-5  my-3" htmlFor="">
                    Bairro:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id="neighborhood"
                    {...register("address.neighborhood", {
                      required: false,
                      value: userPerfil.data?.address?.neighborhood,
                    })}
                  />
                </div>
                <div className="flex w-1/4 flex-col sm:max-md:w-3/4">
                  <label className="mx-5  my-3" htmlFor="">
                    Cidade:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id="city"
                    {...register("address.city", {
                      required: false,
                      value: userPerfil.data?.address?.city,
                    })}
                  />
                </div>
                <div className="flex w-1/4 flex-col sm:max-md:w-3/4">
                  <label className="mx-5  my-3" htmlFor="">
                    Estado:
                  </label>
                  <input
                    className="input-form border-none"
                    type="text"
                    name=""
                    id="state"
                    {...register("address.state", {
                      required: false,
                      value: userPerfil.data?.address?.state,
                    })}
                  />
                </div>
              </div>
              <div className="flex"></div>
              <div className="flex flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Razão social:
                </label>
                <input
                  className="input-form border-none"
                  type="text"
                  name=""
                  id=""
                  {...register("razao_social", {
                    required: false,
                    value: userPerfil.data?.razao_social,
                  })}
                />
              </div>

              <div>
                <button className="primary-color mx-5 my-8 rounded-lg px-6 py-2 font-bold text-white hover:bg-purple-900">
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
          <div className="mx-5 my-10">
            <form action="" onSubmit={handleSubmitEmail(onSubmitUpdateEmail)}>
              <p className="mx-4 text-2xl">Segurança</p>
              <div className="flex flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Atualizar Email
                </label>
                <input
                  className="input-form border-none"
                  type="text"
                  name=""
                  id=""
                  {...registerEmail("new_email", { required: true })}
                />
              </div>
              <button className="primary-color mx-5 my-8 rounded-lg py-2 px-6 font-bold text-white hover:bg-purple-900">
                Atualizar email
              </button>
            </form>
          </div>
          <div className="mx-5 my-5">
            <form
              action=""
              onSubmit={handleSubmitUpdatePassword(onSubmitUpdatePassword)}>
              <p className="mx-4 text-2xl">Alteração de Senha</p>
              <div className="flex flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Alterar Senha
                </label>
                <input
                  className="input-form border-none"
                  type="password"
                  name=""
                  id=""
                  {...registerChangePassword("new_password", {
                    required: true,
                  })}
                />
              </div>
              <div className="flex flex-col">
                <label className="mx-5 my-3" htmlFor="">
                  Confirmar Senha
                </label>
                <input
                  className="input-form border-none"
                  type="password"
                  name=""
                  id=""
                  onKeyDown={(e) => submitOnEnter(e, "submitButton")}
                  {...registerChangePassword("new_password_confirm", {
                    required: true,
                  })}
                />
              </div>
              <div className="flex flex-col">{differentPass}</div>
              <button className="primary-color mx-5 my-8 rounded-lg py-2 px-6 font-bold text-white hover:bg-purple-900">
                Alterar senha
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

import { Link } from "react-router-dom";

export const Error = () => {
  return (
    <>
      <div className="my-20">
        <p className="text-primary-color text-center text-8xl">Whoops!</p>
        <p className="text-primary-color text-center text-5xl">
          404 página não encontrada
        </p>
        <p className="text-primary-color text-center">
          Tente acessar a nossa página principal{" "}
          <Link className="text-lg font-bold underline" to="/">
            Home
          </Link>
        </p>
      </div>
    </>
  );
};

import Login from "../../../assets/images/loginPhoto.jpg";
import { useForm } from "react-hook-form";
import GlobalConfig from "../../../GlobalConfig";
import axios from "axios";
import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { submitOnEnter } from "../../../utils/submitKeyPress";

export const RegisterModal = ({ toogleForm, visible, onClose }) => {
  const { register, handleSubmit } = useForm();
  const [differentPass, setDifferentPassword] = useState(null);
  const { login } = useAuth();
  const {
    http: { url_auth_register, url_auth_login },
  } = GlobalConfig;

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };

  async function createAccount(data) {
    return await axios
      .post(url_auth_register, data)
      .then((response) => response.data);
  }

  async function loginData(data) {
    return await axios
      .post(url_auth_login, data)
      .then((response) => response.data);
  }

  const onSubmit = async (data) => {
    if (data.confirmPassword != data.password)
      setDifferentPassword("senhas diferentes.");
    const res = await createAccount({
      email: data.email.trim(),
      password: data.password,
    });

    if (res.message === "Created") {
      const response = await loginData({
        email: data.email.trim(),
        password: data.password,
      });
      await login({ ...response });
      return onClose();
    } else {
      alert(res);
    }
  };

  if (!visible) return null;

  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="max-w-6xl ">
          <div className="flex flex-row bg-white shadow-lg outline-none focus:outline-none">
            <div className="m-20 my-16 sm:m-0 sm:my-0 sm:p-5">
              <form
                className="flex-col sm:flex sm:items-center sm:justify-center sm:px-3"
                onSubmit={handleSubmit(onSubmit)}>
                <div className="-mx-1 px-4">
                  <p className="text-2xl font-bold underline">Criar conta</p>
                </div>
                <div className="font-mulish flex flex-col">
                  <label className="-mx-1 px-6 py-3">
                    Seu Email
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 border-none focus:outline-none sm:max-md:w-3/4"
                    type="text"
                    {...register("email", {
                      required: true,
                    })}
                  />
                </div>
                <div className="font-mulish flex flex-col">
                  <label className="-mx-1 px-6 py-3">
                    Senha
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 border-none focus:outline-none sm:max-md:w-3/4"
                    type="password"
                    {...register("password", {
                      required: true,
                    })}
                  />
                </div>
                <div className="font-mulish flex flex-col">
                  <label className="-mx-1 px-6 py-3">
                    Confirmar Senha
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="input-form w-96 border-none focus:outline-none sm:max-md:w-3/4"
                    onKeyDown={(e) => submitOnEnter(e, "submitButton")}
                    type="password"
                    {...register("confirmPassword", {
                      required: true,
                    })}
                  />
                  {differentPass}
                </div>
                <div className="font-mulish flex px-6 py-3">
                  <input
                    type="checkbox"
                    className="default:ring-8 invalid:border-red-500"
                    required
                    name="terms"
                    id="terms"
                  />
                  <p className="text-xs text-[#757575] underline">
                    Ao criar uma conta está a concordar com os nossos Termos e
                    Condições e Política de Privacidade
                  </p>
                </div>
                <div className="mx-4 flex flex-col sm:items-center sm:justify-center">
                  <button
                    id="submitButton"
                    className="primary-color mb-6 w-96 rounded-md p-2 text-xl text-white hover:bg-purple-900 sm:max-md:w-3/4">
                    CRIAR CONTA
                  </button>
                  <button
                    className="flex justify-start"
                    onClick={() => toogleForm("login")}>
                    Acessar conta existente
                  </button>
                </div>
              </form>
            </div>
            <div className="px-6 sm:hidden md:block">
              <img
                className="h-full object-cover"
                src={Login}
                alt="mulher digitando no notebook"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

import { useForm } from "react-hook-form";
import { updateNickname } from "../../../api";

export const NicknameModal = ({
  visible,
  onClose,
  idContract,
  handleReload,
}) => {
  const { register, handleSubmit } = useForm();

  const handleOnClose = (e) => {
    if (e.target.id === "container") onClose();
  };
  const onSubmit = async (data) => {
    const input = { ...data, id: idContract };

    await updateNickname(input);
    onClose();
    handleReload();
  };

  if (!visible) return null;

  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none"
      >
        <div className="max-w-6xl">
          <div className="flex flex-row bg-white shadow-lg outline-none focus:outline-none">
            <div className="mx-20 my-16 w-full">
              <div className="m-auto text-center text-[#757575]">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col">
                    <label className="label-form" htmlFor="">
                      Informe o novo nome para o contrato:
                    </label>
                    <input
                      className="input-form w-full "
                      type="text"
                      {...register("apelido", { required: true })}
                    />
                  </div>
                  <button className="mx-5 my-2 rounded-lg bg-[#8DC74A] px-6 py-2 font-bold text-white hover:bg-[#D6DC40]">
                    Salvar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

import FormCard from "../../../components/formCard";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useFetchContract, useFetchContractInitial } from "../../../api";
import { LoginModal } from "../../../components/modals/ModalLogin";
import { RegisterModal } from "../../../components/modals/ModalRegister";
import { PasswordModal } from "../../../components/modals/ModalPassword";

export default function Contract({ currentStep = 0 }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [currentForm, setCurrentForm] = useState("login");
  const [modal, setShowModal] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [formStep1, setFormStep1] = useState({
    contratante: "",
    contratada: "",
  });
  const [isFocused, setIsFocused] = useState({});
  const id = useParams();
  const prevFormStep = () => setWizardStep((currentStep) => currentStep - 1);
  const methods = useForm();
  const formValues = methods.watch();
  const contractQuery = useFetchContract(id.idContract, formStep1, false);
  const contractInitial = useFetchContractInitial(id.idContract, true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (currentStep === 1) {
      nextFormStep();
    }
  }, []);

  const toogleForm = (formName) => {
    setCurrentForm(formName);
  };

  function showModal(currentForm) {
    switch (currentForm) {
      case "register":
        return (
          <RegisterModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );

      case "login":
        return (
          <LoginModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      case "password":
        return (
          <PasswordModal
            toogleForm={toogleForm}
            visible={modal}
            onClose={setShowModal}
          />
        );
      default:
        break;
    }
  }

  function nextFormStep() {
    if (!user) {
      setShowModal(true);
      showModal(currentForm);
    } else {
      contractQuery.refetch();
      setWizardStep((currentStep) => currentStep + 1);
    }
  }

  const inputForm1ChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormStep1({ ...formStep1, [name]: value });
  };

  if (contractQuery?.isLoading) {
    return (
      <section className="m-10 p-28 text-center text-4xl font-bold">
        Carregando...
      </section>
    );
  }
  if (contractQuery?.isError) {
    return (
      <section className="p-28 text-center text-3xl font-bold">
        Desculpe, não conseguimos carregar o seu contrato. Tente novamente mais
        tarde.
      </section>
    );
  }

  let title = contractInitial?.data?.titulo;
  const index = contractInitial?.data?.titulo?.indexOf("(");
  if (index >= 0) {
    title = title.substring(0, index - 1);
  }

  const generateInputs = () => {
    let inputsFocus = [];
    let spanFocus = [];
    if (!formValues) return null;
    const formValuesKeys = Object.keys(formValues);
    for (let index = 0; index < formValuesKeys.length; index++) {
      inputsFocus.push(document.getElementById(formValuesKeys[index]));
      spanFocus.push(document.getElementById(`${formValuesKeys[index]}1`));
    }
    return { inputsFocus, spanFocus };
  };

  const scrollMyFriend = () => {
    if (width < 768) return null;
    const response = generateInputs();
    if (!response?.inputsFocus && !response?.spanFocus) return null;
    const inputArray = response.inputsFocus;
    const docSpanArray = response.spanFocus;
    for (let index = 0; index < inputArray.length; index++) {
      inputArray[index]?.addEventListener("focus", function () {
        docSpanArray[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
      });
    }
  };

  scrollMyFriend();

  return (
    <>
      <FormProvider {...methods}>
        {showModal(currentForm)}
        <div className="absolute -z-10 h-8 w-full bg-gradient-to-b from-[#44377C] to-[#7C6BCB] drop-shadow-md "></div>
        <div className="flex justify-center sm:flex-col sm:max-md:w-full md:m-10 md:flex-col lg:flex-row">
          <div className="m-auto mt-0 flex flex-col items-center  sm:mt-10">
            <div className="text-primary-color m-auto text-2xl font-bold">
              {title}
            </div>
            <FormCard
              idContract={id.idContract}
              idUserContract={id.idUserContract}
              nextFormStep={nextFormStep}
              prevFormStep={prevFormStep}
              formStep={wizardStep}
              inputChangeHandler={inputForm1ChangeHandler}
              formStep1={formStep1}
              setIsFocused={setIsFocused}></FormCard>
            {wizardStep === 0 ? (
              <div className="w-full p-2 sm:w-2/3 md:text-center lg:text-left">
                <div className="text-primary-color  mb-2 text-2xl font-bold">
                  <p>Sobre o {title}</p>
                </div>
                <div className="text-lg text-black">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contractInitial?.data?.sobre,
                    }}></div>
                </div>
              </div>
            ) : null}
          </div>
          {wizardStep === 0 ? (
            <div className="lg:page lg:subpage md:page-md prevent-select flex flex-col items-center text-center">
              <p className="mt-16 w-2/3 text-xl text-black">
                Ao preencher o formulário você verá o seu <b>contrato aqui!</b>{" "}
              </p>
              <p className="-mt-2 w-2/3 text-xl">
                O seu contrato ficará <b> armazenado em seu perfil</b> após ato
                de pagamento.
              </p>
              <p className="-mt-2 w-2/3 text-xl font-bold">
                Fique tranquilo, após salvar você poderá editar o seu contrato
                sempre que precisar!
              </p>
              <p className="-mt-2 w-2/3 text-xl">
                Tenha em mãos os dados dos contratantes para sua experiência ser
                ainda mais rápida e prática.
              </p>
            </div>
          ) : null}
          {wizardStep === 1 ? (
            <div className="page prevent-select h-screen overflow-auto text-justify sm:max-md:m-1 sm:max-md:h-full sm:max-md:w-full sm:max-md:min-w-full sm:max-md:max-w-none sm:max-md:p-1">
              <div
                className="overflow-y-auto sm:max-md:w-full"
                dangerouslySetInnerHTML={{
                  __html: eval("`" + contractQuery?.data?.html + "`"),
                }}></div>
            </div>
          ) : null}
        </div>
      </FormProvider>
    </>
  );
}

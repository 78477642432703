import Step1 from "../step1";
import Step2 from "../step2";

export default function FormCard({
  idContract,
  formStep,
  nextFormStep,
  prevFormStep,
  inputChangeHandler,
  formStep1,
  setIsFocused,
  idUserContract,
}) {
  return (
    <div className="w-fit sm:max-md:w-full justify-center">
      <div className="h-max rounded-lg border">
        {formStep == 0 && (
          <Step1
            formStep={formStep}
            nextFormStep={nextFormStep}
            inputChangeHandler={inputChangeHandler}
          />
        )}
        {formStep == 1 && (
          <Step2
            prevFormStep={prevFormStep}
            formStep1={formStep1}
            idContract={idContract}
            setIsFocused={setIsFocused}
            idUserContract={idUserContract}
          />
        )}
      </div>
    </div>
  );
}

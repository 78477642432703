export const Policy = () => {
  return (
    <div className="text-primary-color flex sm:m-auto md:m-auto lg:m-auto">
      <div className="sm:px-10 md:px-16 lg:px-40">
        <p className="my-10 text-center text-xl">
          <u>
            <strong>TERMOS DE USO E PRIVACIDADE – USUÁRIO</strong>
          </u>
        </p>
        <div className="my-20 lg:px-20">
          <p>
            <strong>Atualização em 20 de Janeiro de 2023</strong>
          </p>
          <p>
            <strong>
              Seja muito bem-vindo à nossa Plataforma Digital.&nbsp;
            </strong>
          </p>
          <p>
            <strong>Symbora empreender!</strong>
          </p>
          <p>
            <u>
              <strong>INTRODUÇÃO E DISPOSIÇÕES GERAIS</strong>
            </u>
          </p>
          <p>
            1.1.Estes Termos de Uso e Privacidade (doravante denominado
            simplesmente “Termos”), regerão os direitos e obrigações no acesso
            do <em>website</em>{" "}
            <u>
              <a href="http://www.symbora.com.br/">www.symbora.com.br</a>
            </u>{" "}
            (a “<strong>Plataforma Digital</strong>”), e/ou do aplicativo para
            uso em celular a ser desenvolvido (“<em>app”)&nbsp;</em>que
            consistirá na contratação dos serviços a serem disponibilizados para
            as pessoas interessadas na sua contratação (“
            <u>
              <strong>Serviços</strong>
            </u>
            ” e “
            <u>
              <strong>Usuários</strong>
            </u>
            ”), de exclusiva propriedade da{" "}
            <u>
              <strong>SYMBORA SOLUÇÕES DIGITAIS LTDA</strong>
            </u>
            <strong>&nbsp;</strong>(“<strong>SYMBORA</strong>”), sociedade
            empresária limitada com sede na Cidade e Estado de São Paulo, na Av.
            Brigadeiro Faria Lima nº 2.128, Conjunto 304, Jardim Paulistano, CEP
            01451-903, inscrita no CNPJ/ME sob o nº 35.726.073/0001-40, ao
            anuente destes Termos, que decida utilizar a{" "}
            <strong>Plataforma Digital</strong> da <strong>SYMBORA.</strong>
          </p>
          <p>
            1.1.1. Os presentes Termos de Uso incluem os Termos de Política de
            Privacidade da <strong>Plataforma Digital</strong>, disponível no
            link www.symbora.com.br.
          </p>
          <p>
            1.2.
            <u>
              <strong>ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO</strong>
            </u>
            . A aceitação destes Termos é absolutamente indispensável à
            utilização da <strong>Plataforma Digital</strong>. O{" "}
            <strong>Usuário</strong> deverá ler e certificar-se de haver
            entendido e aceitar todas as condições estabelecidas nestes Termos
            antes de seu cadastro como <strong>Usuário</strong> no site e/ou na{" "}
            <strong>Plataforma Digital</strong>.
          </p>
          <p>
            1.2.1.Ao se cadastrar e utilizar continuamente a{" "}
            <strong>Plataforma Digital</strong>, o <strong>Usuário</strong>
            declara ter lido, entendido e anuído expressamente com estes Termos.
            Caso, a qualquer tempo, o <strong>Usuário</strong> não concorde com
            estes Termos, deverá cessar imediatamente a utilização da{" "}
            <strong>Plataforma Digital</strong>, bem como a utilização dos
            Serviços através da mesma.
          </p>
          <p>
            1.2.2.Deve ser ressaltado que termos adicionais poderão se aplicar a
            determinados Serviços, tais como condições para{" "}
            <strong>Usuários</strong> em categorias específicas, eventos,
            regulamentos, atividades, promoções em particular ou ações de
            recompensas, sendo certo que os Termos adicionais serão divulgados
            na <strong>Plataforma Digital</strong> em relação aos respectivos
            Serviços (os “<u>Termos Adicionais</u>”).
          </p>
          <p>
            1.3.3.Os Termos Adicionais são complementares e considerados parte
            integrante destes Termos para os efeitos de outros serviços. Os
            Termos Adicionais prevalecerão sobre estes Termos em caso de
            conflito com relação àquilo que forem específicos.
          </p>
          <p>
            1.4.
            <u>
              <strong>COMUNICAÇÃO COM O USUÁRIO</strong>
            </u>
            . O <strong>Usuário </strong>
            autoriza a <strong>SYMBORA</strong> a enviar notificações
            administrativas na <strong>Plataforma Digital</strong> (“
            <em>push</em>”), assim como através de e-mail, “SMS”, publicação no
            seu <em>website</em>, ou por meio de qualquer outra forma de
            comunicação disponível (“Meios de Comunicação”) com conteúdo de
            natureza informativa e promocional relacionado aos Serviços.
          </p>
          <p>
            1.5.
            <u>
              <strong>ALTERAÇÃO DOS TERMOS</strong>
            </u>
            . A <strong>SYMBORA</strong> se reserva ao direito de realizar
            alterações e atualizações nestes Termos, a qualquer momento, sem a
            necessidade de aviso prévio, especialmente para atender a legislação
            em vigor. Em caso de alterações nestes Termos, que venham a
            restringir eventuais direitos dos <strong>Usuários</strong>, a{" "}
            <strong>SYMBORA</strong> poderá fazer uma comunicação, a seu
            exclusivo critério, através dos Meios de Comunicação. Não obstante
            esta possibilidade de alteração dos Termos, o{" "}
            <strong>Usuário</strong> obriga-se, neste ato, a verificar
            periodicamente os Termos de Uso e Privacidade, bem como a respectiva
            Política da <strong>SYMBORA</strong>.
          </p>
          <p>
            1.5.1Caso o <strong>Usuário</strong> não concorde com as alterações
            destes Termos, deverá cancelar a sua conta gratuita (“Conta
            Pessoal”), cessar toda e qualquer utilização da{" "}
            <strong>Plataforma Digital</strong>, ficando expressamente impedido
            de utilizar os Serviços através da{" "}
            <strong>Plataforma Digital</strong> da <strong>SYMBORA</strong>. O
            fato de o <strong>Usuário </strong>
            continuar a acessar ou disponibilizar os Serviços após a alteração
            dos Termos representa o seu consentimento expresso em vincular-se
            aos Termos conforme alterados.
          </p>
          <p>
            1.5.2.O <strong>Usuário</strong> está ciente de que a não anuência
            quanto os Termos e o consequente cancelamento são irreversíveis.
            Ocorrendo esta hipótese, todas as informações de sua conta,
            incluindo os contratos criados e armazenados na nuvem da{" "}
            <strong>Plataforma Digital</strong> serão impreterivelmente
            apagadas, anuindo que não será possível recuperar qualquer contrato
            ou dado perdido em razão do cancelamento do acesso à{" "}
            <strong>Plataforma Digital</strong>. O <strong>Usuário</strong>{" "}
            reconhece que não poderá responsabilizar a <strong>SYMBORA</strong>{" "}
            por qualquer ato em decorrência do cancelamento da Conta Pessoal do{" "}
            <strong>Usuário</strong>.
          </p>
          <p>
            <u>
              <strong>2.</strong>
            </u>
            <u>
              <strong>SYMBORA STARTUP – PLATAFORMA DIGITAL</strong>
            </u>
          </p>
          <p>
            2.1.A <strong>SYMBORA</strong> tem como propósito o oferecimento de
            um ambiente virtual seguro que promova o incentivo à criação e
            desenvolvimento de startups por meio da disponibilização de{" "}
            <em>templates</em> e gerenciamento de contratos, consultoria
            empresarial, através de artigos, dicas, e intermediação entre o{" "}
            <strong>Usuário</strong> e um advogado, tudo para auxiliar o
            empreendedor <strong>Usuário</strong>
            em todos os momentos desde a concepção da ideia, perpassando pela
            validação e tração, até o momento da expansão da startup.
          </p>
          <p>
            2.2.A <strong>SYMBORA</strong> cumpre este propósito por meio de uma{" "}
            <strong>Plataforma Digital</strong>, que funciona precipuamente com
            conexão à Internet, e disponibiliza ao <strong>Usuário</strong> uma
            licença restrita, ocasional, não exclusiva, intransferível, a{" "}
            <em>prima facie</em> revogável pelas prerrogativas concedidas à
            <strong>&nbsp;SYMBORA</strong> para, em contrapartida ao pagamento
            de planos anuais ou por demanda, crie seus próprios contratos,
            personalizando os contratos padrões disponibilizados pela{" "}
            <strong>Plataforma Digital</strong> de acordo com as necessidades do{" "}
            <strong>Usuário,&nbsp;</strong>se beneficie de consultoria
            empresarial, do armazenamento destes contratos criados em nuvem e do
            aprendizado com vídeo-aulas para criação, desenvolvimento, e criação
            de sua startup (doravante referidos como “Serviços”).
          </p>
          <p>
            2.3Em relação à personalização dos contratos, o{" "}
            <strong>Usuário</strong> se declara ciente que os modelos de minutas
            de contrato padronizados foram elaborados estritamente em
            consonância com o ordenamento aplicável atualmente na legislação
            brasileira, jurisprudência e de acordo com as corriqueiras práticas
            de mercado, mas que podem não ser satisfatório para abarcar todas as
            particularidades e riscos decorrentes das relações negociais ou da
            necessidade específica do <strong>Usuário.</strong> Neste sentido, a{" "}
            <strong>SYMBORA</strong>, sob qualquer prisma, não se responsabiliza
            por eventuais danos decorrentes da utilização dos contratos gerados
            pela <strong>Plataforma Digital</strong>, sendo de inteira
            responsabilidade do <strong>Usuário</strong> a escolha do tipo de
            contrato e sua análise quanto ao atendimento deste contrato diante
            do seu caso concreto antes da assinatura do mesmo, e desde já,
            reconhece, de forma irrevogável e irretratável, que a{" "}
            <strong>SYMBORA</strong> não possui qualquer responsabilidade nos
            resultados da utilização dos
            <strong> Serviços.&nbsp;</strong>
          </p>
          <p>
            2.4.Consoante ao disposto no item acima, a{" "}
            <strong>Plataforma Digital&nbsp;</strong>da{" "}
            <strong>SYMBORA </strong> NÃO PRESTA QUALQUER TIPO DE ASSESSORIA
            JURÍDICA, E POR ENTENDER QUE NÃO PODEM SER SUBESTIMADO, não
            existindo qualquer tipo de relação de prestação de serviços
            advocatícios por parte da <strong>SYMBORA.</strong> Ao máximo, a{" "}
            <strong>SYMBORA</strong> poderá, por meio de mecanismo que coloque o{" "}
            <strong>Usuário</strong> em contato, indicar advogados aos{" "}
            <strong>Usuários</strong> de modo a melhor atendê-lo. De toda a
            forma, a <strong>SYMBORA</strong> incentiva, em caso de dúvidas e
            desconhecimento jurídico do <strong>Usuário</strong> a consulta a um
            advogado(a).
          </p>
          <p>
            2.5.A <strong>SYMBORA</strong> não se responsabiliza por quaisquer
            danos sofridos pelo <strong>Usuário</strong> em razão de cópia,
            transferência, distribuição ou qualquer outra forma de utilização de
            conteúdo protegido disponibilizado na{" "}
            <strong>Plataforma Digital</strong>, que venha a ser obtido de forma
            ilícita por terceiros.
          </p>
          <p>
            2.6.A <strong>SYMBORA</strong> reserva a si e exclusivamente todos
            os direitos inerentes sobre o<em>layout</em>, uso da sua marca e
            direitos autorais inerentes à <strong>Plataforma Digital</strong>.
          </p>
          <p>
            2.7.O uso da <strong>Plataforma Digital</strong>, é, em geral,
            gratuito. A<strong> SYMBORA</strong> informará previamente o{" "}
            <strong>Usuário</strong> sobre funcionalidades e condutas que possam
            gerar cobranças e/ou pagamentos pelo Usuário de forma específica
            pela <strong>Plataforma Digital</strong>, pelos Meios de Comunicação
            e/ou por atualização destes Termos. A <strong>SYMBORA</strong> se
            reserva o direito de passar a cobrar por qualquer tipo de Serviço ou
            parte dele a qualquer tempo. O<strong> Usuário</strong> será
            previamente informado caso isso ocorra ou terá a oportunidade de
            consentir com tais cobranças pelos novos Serviços ou cessar o uso do
            mesmo.
          </p>
          <p>
            2.8.Os Serviços não devem ser utilizados para qualquer finalidade
            que não as expressamente autorizadas por estes Termos.
          </p>
          <p>
            2.9.
            <u>
              <strong>
                RESPONSABILIDADE PELOS SERVIÇOS CONTRATADOS NA PLATAFORMA
                DIGITAL
              </strong>
            </u>
            . A <strong>SYMBORA</strong> não se responsabiliza por quaisquer
            perdas, prejuízos ou danos de qualquer natureza que sejam
            decorrentes da relação entre <strong>Usuários</strong> e as pessoas
            que contratar. O <strong>Usuário</strong> entende e concorda que a{" "}
            <strong>SYMBORA</strong> não será responsável por quaisquer danos ou
            prejuízos que venham a ser causados em razão da desídia do{" "}
            <strong>Usuário</strong> quando da elaboração do contrato.
          </p>
          <p>
            2.10.O <strong>Usuário</strong> é o único integral responsável pelo
            conteúdo do contrato que personalizar, respondendo o{" "}
            <strong>Usuário</strong> por qualquer infração à legislação em vigor
            e em qualquer âmbito.
          </p>
          <p>
            2.11.
            <u>
              <strong>Acesso à Rede e Equipamentos</strong>
            </u>
            . O <strong>Usuário</strong> entende e concorda que a utilização do
            Serviço demanda a aquisição de dispositivos de telefonia móvel e/ou
            de computadores e a contratação de serviços de telecomunicação
            compatíveis com o uso da <strong>Plataforma Digital</strong>, e que
            a utilização dos Serviços poderá gerar cobranças por parte de tais
            prestadores de serviço de telecomunicação para conexão com a
            Internet, por exemplo. O <strong>Usuário</strong> é o responsável
            exclusivo por contratar e arcar com todo e qualquer custo e ônus
            relacionados à aquisição de seu dispositivo de telefonia móvel e a
            contratação de seu serviço de telecomunicação. A{" "}
            <strong>SYMBORA</strong> não é responsável pela disponibilidade,
            qualidade e manutenção de tais serviços de telecomunicação e o{" "}
            <strong>Usuário</strong> entende que as condições do serviço de
            telecomunicações poderão afetar a experiência do Serviço. A{" "}
            <strong>SYMBORA</strong> não será responsabilizada por qualquer
            problema relacionado ao Serviço decorrente direta ou indiretamente
            de inconsistências ou falhas nos dispositivos de telefonia móvel
            e/ou nos serviços de telecomunicação.
          </p>
          <p>
            2.12.{" "}
            <u>
              <strong>Modificação ou Encerramento dos Serviços</strong>
            </u>
            . A <strong>SYMBORA</strong> se reserva o direito de, a qualquer
            tempo, modificar ou descontinuar, temporariamente ou
            permanentemente, os Serviços e/ou a{" "}
            <strong>Plataforma Digital</strong>, ou parte dela, com ou sem
            notificação das atividades disponibilizadas. O{" "}
            <strong>Usuário</strong> concorda que a <strong>SYMBORA</strong> não
            será responsabilizada, nem terá qualquer obrigação adicional,
            implícita ou explícita, perante o <strong>Usuário</strong> em razão
            de qualquer modificação, suspensão ou desativação dos Serviços e/ou
            da <strong>Plataforma Digital</strong>, a não ser pelos Serviços
            previamente contratados ou em execução<strong>.</strong>
          </p>
          <p>
            <u>
              <strong>3.</strong>
            </u>
            <u>
              <strong>FORMA DE ACESSO À PLATAFORMA DIGITAL E CADASTRO</strong>
            </u>
          </p>
          <p>
            3.1.Para utilizar a <strong>Plataforma Digital</strong>, o{" "}
            <strong>Usuário</strong> deverá registrar-se e manter apenas uma
            Conta Pessoal de <strong>Usuário</strong>. Fica desde já ressalvado
            que o <strong>Usuário</strong> deverá ter capacidade civil, tendo
            idade mínima de 18 (dezoito) anos para criar seu perfil, a menos que
            seja emancipado ou tenha obtido plena capacidade civil nos termos da
            legislação civil em vigor, podendo-se, ainda, efetuar cadastro a
            pessoa jurídica de direito privado.
          </p>
          <p>
            3.2.O <strong>Usuário</strong> que desejar utilizar os Serviços
            deverá obrigatoriamente, e voluntariamente, preencher os campos de
            cadastro com todas as informações exigidas, inclusive relativos aos
            dados necessários para pagamento de valores quando da utilização dos
            Serviços e responderá pela veracidade das informações declaradas,
            obrigando-se a manter informações válidas, atualizadas e corretas. O
            perfil do <strong>Usuário</strong> é exclusivo e intransferível. Em
            caso de não confirmação de seus dados, o acesso do{" "}
            <strong>Usuário</strong> aos Serviços poderá ser bloqueado, a
            exclusivo critério da <strong>SYMBORA</strong> e este Termo de Uso
            rescindido.
          </p>
          <p>
            3.3.Caso a <strong>SYMBORA&nbsp;</strong>constate a ocorrência de
            alguma conta <em>fake</em>, criada com dados falsos sobre qualquer
            tipo de informação, a <strong>SYMBORA</strong> poderá suspender a
            conta e verificada a prática de fraude, a Conta Pessoal será
            cancelada imediatamente.
          </p>
          <p>
            3.4.Caso o cadastro seja realizado em nome de pessoa jurídica de
            direito privada, a pessoa responsável por este cadastro deverá
            declarar que possui poderes para realizar todo tipo de procedimento
            na <strong>Plataforma Digital</strong>, não sendo a{" "}
            <strong>SYMBORA</strong> responsável, sob qualquer forma, em caso de
            irregularidades ou prática de fraudes.
          </p>
          <p>
            3.5.Realizado o cadastro, o <strong>Usuário</strong> se declara
            responsável pelo sigilo e segurança de seu login e senha, não
            permitindo que esta conta seja utilizada por terceiros, se
            responsabilizando por toda e qualquer consequências da desídia
            quanto à segurança da conta, não subsistindo, sob qualquer forma, a
            responsabilidade da <strong>SYMBORA</strong> em relação a eventuais
            danos que possam decorrer da perda ou do extravio da senha do{" "}
            <strong>Usuário</strong>. De toda forma, declara o{" "}
            <strong>Usuário&nbsp;</strong>estar ciente que em caso de
            esquecimento da senha e/ou extravio da senha, deverá reportar
            imediatamente à <strong>SYMBORA.</strong>
          </p>
          <p>
            <u>
              <strong>4.</strong>
            </u>
            <u>
              <strong>
                DA CONTRATAÇÃO DOS PLANOS E DO PAGAMENTO DOS SERVIÇOS
              </strong>
            </u>
          </p>
          <p>
            4.1.Os Serviços oferecidos pela <strong>SYMBORA</strong> poderão ser
            contratados pelos <strong>Usuários</strong> mediante o pagamento da
            personalização do contrato escolhido isolado (“Preço do Contrato”)
            ou por meio de planos serão divididos pelos contratos aplicáveis a
            cada fase sequencial em que a startup se encontra, como a (i) fase
            de ideia; (ii) fase de validação; (iii) fase de tração; e (iv) fase
            de expansão, bem como a prestação de outros Serviços (“Planos”),
            oferecidos de forma onerosa ao <strong>Usuário</strong> (“Preço dos
            Planos” e em conjunto com “Preços dos Contratos”, simplesmente
            “Preço” ou “Preços”).
          </p>
          <p>
            4.2.Os Preços estarão disponíveis na Plataforma e a{" "}
            <strong>SYMBORA</strong> se reserva o direito de estabelecer,
            remover e/ou revisar o Preço relativo aos planos e à personalização
            do contrato de forma isolada oferecidos na{" "}
            <strong>Plataforma Digital</strong> a qualquer momento, a critério
            exclusivo da <strong>SYMBORA</strong>. Ademais, o{" "}
            <strong>Usuário</strong> reconhece e concorda que após o pagamento
            do Preço, não será permitida, em nenhuma hipótese a devolução do
            valor pago, e que não haverá outros valores que não os dispostos na{" "}
            <strong>Plataforma Digital</strong>, estando incluindo nos Preços os
            tributos atinentes à contratação dos Serviços.
          </p>
          <p>
            4.3.Efetuado o pagamento dos Preços, os Serviços serão
            disponibilizados tão logo seja possível, sendo o{" "}
            <strong>Usuário</strong> avisado da confirmação do pagamento por
            meio de mensagem disponibilizada tanto na plataforma quanto por meio
            de correspondência eletrônica a ser endereço ao endereço de e-mail
            informado pelo <strong>Usuário</strong> quando do momento do
            Cadastro.
          </p>
          <p>
            4.4.O pagamento do Preço será realizado por meio da própria{" "}
            <strong>Plataforma Digital</strong>, de acordo com as
            funcionalidades dispostas no portal de pagamento. A confirmação do
            pagamento será realizada dentro do prazo de 5 (cinco) dias, e o{" "}
            <strong>Usuário</strong> se declara ciente que a confirmação do
            pagamento independe da <strong>SYMBORA</strong>, sendo realizado por
            sistema de terceiros, razão pela qual inexistirá qualquer tipo de
            responsabilidade da <strong>SYMBORA</strong> em decorrência de
            atraso desta comunicação.
          </p>
          <p>
            <u>
              <strong>4.5.</strong>
            </u>
            <u>
              <strong>DA ASSINATURA DOS PLANOS.</strong>
            </u>{" "}
            O oferecimento dos Serviços por meio dos Planos é uma forma de
            estimular o empreendedorismo de acordo com a fase que a startup se
            encontre, de modo que os planos se basearão na fidelidade de
            contratação do plano pelo período de 12 (doze) meses (“Período de
            Contratação do Plano”), que serão cobrados mensalmente, sem que
            importe no bloqueio deste valor no cartão de crédito do{" "}
            <strong>Usuário.</strong>
          </p>
          <p>
            4.5.1.Os Serviços dos Planos ocorrerão por meio do acesso ilimitado
            à personalização de contratos utilizáveis e por disponibilização,
            forma individualizada, de consultoria empresarial com especialistas
            com carga horária de acordo com a fase sequencial (“Fase Sequencial
            do Plano”) da startup contratado pelo <strong>Usuário</strong>.
          </p>
          <p>
            4.5.1.1.O <strong>Usuário</strong> que contratar os Planos avançados
            na Fase Sequencial do Plano terá acesso ilimitado à personalização
            de contratos oferecidos nos Planos anteriores.
          </p>
          <p>
            4.5.2.A <strong>SYMBORA&nbsp;</strong>poderá alterar os Preços dos
            Planos (“Alteração dos Preços dos Planos”) unilateralmente, sem que
            importe em qualquer prejuízo ao <strong>Usuário</strong> durante o
            Período de Contratação do Plano, permanecendo o mesmo preço quando
            da contratação do Plano. A Alteração dos Preços dos Planos somente
            incidirá se o <strong>Usuário</strong> decidir pela renovação do
            Plano, sendo que esta alteração será comunicada previamente pela{" "}
            <strong>SYMBORA</strong> aos <strong>Usuários</strong>.
          </p>
          <p>
            4.5.3.Conforme o disposto no item 1.2., poderão ser promovidos pela{" "}
            <strong>SYMBORA</strong> ofertas e promoções e disponibilização de
            cupons para redução dos Preços dos Planos, que serão definidas
            conforme os Termos Adicionais que serão elaborados à época da
            divulgação, podendo, ainda a <strong>SYMBORA</strong> realizar
            parcerias com outras entidades privadas por meio de códigos
            promocionais que terão a determinação dos Preços revisados pela{" "}
            <strong>SYMBORA.</strong>
          </p>
          <p>
            4.5.4.Diante da pluralidade de planos para contemplar as fases
            ocorridas durante o desenvolvimento da <em>startup</em>, poderá o{" "}
            <strong>Usuário&nbsp;</strong>requerer o <em>upgrade</em> do plano
            anteriormente escolhido durante o Período de Contratação do Plano,
            quando ocorrerá a alteração da cobrança do Preço do Plano de acordo
            com o Plano escolhido (“<em>Upgrade&nbsp;</em>do Plano”).
          </p>
          <p>
            <u>
              <strong>5.</strong>
            </u>
            <u>
              <strong>DO CANCELAMENTO DA ASSINATURA DOS PLANOS</strong>
            </u>
          </p>
          <p>
            5.1.O <strong>Usuário</strong> possui 7 (sete) dias para declarar o
            seu arrependimento da assinatura dos planos, desde que não elabore
            qualquer contrato. O pedido de cancelamento da assinatura do Plano
            contratado após a personalização de qualquer contrato configurará o
            consumo dos Serviços, não podendo ser reembolsável.
          </p>
          <p>
            5.2.De acordo com o item 4.5., caso o cancelamento seja requerido
            durante o Período de Contratação do Plano, o{" "}
            <strong>Usuário</strong> se declara ciente e concorda que será
            imediatamente cobrado de seu cartão de crédito os valores referentes
            aos meses restantes para encerrar o Período de Contratação do Plano.
          </p>
          <p>
            5.3.O cancelamento do plano não importará na perda dos dados e
            contratos gerenciados na Conta Pessoal do <strong>Usuário</strong>{" "}
            até o prazo final da contratação, sendo certo que (i) poderá ser
            requisitado a qualquer tempo, (ii) que o <strong>Usuário</strong>{" "}
            terá acesso a todos os Serviços até o final do Contrato até o final
            do período contratado; e (iii) que não importará na devolução de
            quaisquer valores já pagos pela assinatura do plano.
          </p>
          <p>
            <u>
              <strong>6.</strong>
            </u>
            <u>
              <strong>
                INEXISTÊNCIA DE GARANTIA E LIMITAÇÃO DE RESPONSABILIDADE
              </strong>
            </u>
          </p>
          <p>
            6.1.
            <u>
              <strong>RECUSA DE GARANTIA</strong>
            </u>
            . O <strong>Usuário</strong> expressamente concorda e está ciente de
            que: (i) a utilização dos Serviços será sob inteira responsabilidade
            do <strong>Usuário</strong>. Os Serviços são fornecidos aos{" "}
            <strong>Usuários</strong> na forma em que estão disponíveis. A{" "}
            <strong>SYMBORA</strong> não oferece garantias outras além das
            expressamente estabelecidas nestes termos; e (ii) a{" "}
            <strong>SYMBORA</strong> não pode garantir que: (a) o serviço à
            disposição atenderá às necessidades do <strong>Usuário</strong>; (b)
            que a qualidade de quaisquer outras ofertas, informações ou outro
            material acessado, obtido, disponibilizado ou prestado ao
            <strong>Usuário</strong> em conexão ao serviço atenderá às
            expectativas; (d) que melhoramentos ou inovações serão
            implementados.
          </p>
          <p>
            6.2.
            <u>
              <strong>LIMITAÇÃO DE RESPONSABILIDADE PELOS SERVIÇOS.</strong>
            </u>{" "}
            O <strong>Usuário</strong> expressamente concorda e está ciente de
            que a <strong>SYMBORA</strong> não terá qualquer responsabilidade,
            seja contratual ou extra-contratual, por quaisquer danos
            patrimoniais ou morais, incluindo, sem limitação, danos por lucros
            cessantes ou de informações ou outras perdas intangíveis resultantes
            do: (a) uso ou incapacidade de usar os Serviços; (b) quebras de
            segurança e acesso não autorizado às transmissões ou informações do{" "}
            <strong>Usuário</strong>, bem como da alteração destas; (c)
            orientações ou condutas de terceiros sobre os Serviço; e (d) por
            motivos de força maior ou caso fortuito, atos praticados pelo
            próprio <strong>Usuário</strong> e atos praticados por ou sob a
            responsabilidade de terceiros.
          </p>
          <p>
            6.3.O <strong>Usuário</strong> se declara ciente de que os dados dos
            contratos elaborados pela divulgados pela{" "}
            <strong>SYMBORA&nbsp;</strong>foram fornecidos pelo próprio{" "}
            <strong>Usuário</strong>.
          </p>
          <p>
            6.4.O <strong>Usuário</strong> concorda em indenizar e isentar a{" "}
            <strong>SYMBORA</strong>, diretores e empregados, parceiros e
            clientes que exibam suas marcas, por quaisquer perdas e danos que
            venham a ser demandados, incluindo despesas judiciais e honorários
            advocatícios, em razão: (i) de qualquer fato ou ato ilícito
            praticado pelo <strong>Usuário</strong>; (ii) pela violação destes
            Termos; ou (iii) violação dos direitos de terceiros.
          </p>
          <p>
            <u>
              <strong>7.</strong>
            </u>
            <u>
              <strong>
                SUSPENSÃO E CANCELAMENTO DE SEU ACESSO À PLATAFORMA DIGITAL
              </strong>
            </u>
          </p>
          <p>
            7.1.O<strong>&nbsp;Usuário</strong> concorda que a
            <strong>&nbsp;SYMBORA</strong>, à sua livre discricionariedade,
            poderá suspender ou cancelar a utilização da{" "}
            <strong>Plataforma Digital</strong> pelo <strong>Usuário</strong>,
            incluindo, mas não se limitando:
          </p>
          <ol type="i">
            <li>
              <p>por descumprimentos e/ou violação destes Termos;</p>
            </li>
            <li>
              <p>
                em função de ordem judicial ou requisição legal de autoridade
                pública competente;
              </p>
            </li>
            <li>
              <p>
                por requisição do próprio <strong>Usuário</strong>;
              </p>
            </li>
            <li>
              <p>
                por desativação ou modificação dos serviços (ou de qualquer de
                suas partes);
              </p>
            </li>
            <li>
              <p>por caso fortuito, força maior e/ou questões de segurança;</p>
            </li>
            <li>
              <p>
                pela suposta prática de qualquer atividade fraudulenta ou ilegal
                por parte do <strong>Usuário</strong>, a critério da{" "}
                <strong>SYMBORA</strong>;
              </p>
            </li>
            <li>
              <p>
                pelo uso inadequado ou abusivo da{" "}
                <strong>Plataforma Digital</strong>, a critério da
                <strong>&nbsp;SYMBORA</strong>;
              </p>
            </li>
            <li>
              <p>
                por inadimplemento por parte do <strong>Usuário</strong> de
                quaisquer obrigações, valores, pagamentos devidos;
              </p>
            </li>
            <li>
              <p>
                a reunião de informações sobre a{" "}
                <strong>Plataforma Digital</strong> ou outros{" "}
                <strong>Usuários</strong> sem o consentimento escrito da{" "}
                <strong>SYMBORA</strong>;
              </p>
            </li>
            <li>
              <p>
                modificar, enquadrar, fornecer (ou fornecer novamente),
                refletir, truncar, injetar, filtrar ou alterar qualquer conteúdo
                ou informações contidas na <strong>Plataforma Digital</strong>{" "}
                sem o consentimento escrito da <strong>SYMBORA</strong>;
              </p>
            </li>
            <li>
              <p>
                utilizar qualquer <em>deeplink</em>, <em>web scraping</em>,
                robô, rastreador, índice, <em>spider</em>, <em>click spam</em>,
                macros, agente de Internet ou outros dispositivos, programas,
                algoritmos ou metodologia automáticos que tenham a mesma função,
                para usar, acessar, copiar, adquirir informações, gerar
                impressões ou cliques, incluir informações, armazenar
                informações, buscar, gerar buscas ou monitorar a{" "}
                <strong>Plataforma Digital</strong> e/ou qualquer parte ou
                conteúdo dela;
              </p>
            </li>
            <li>
              <p>
                disfarçar a origem de informações transmitidas para, de ou por
                meio da <strong>Plataforma Digital</strong>;
              </p>
            </li>
            <li>
              <p>distribuir vírus ou outros códigos de computação perigosos;</p>
            </li>
            <li>
              <p>
                utilizar a <strong>Plataforma Digital</strong> para qualquer
                finalidade que viole leis municipais, estaduais, nacionais e
                internacionais, incluindo, dentre outros, o uso não autorizado
                de qualquer conteúdo ou informações contidas na{" "}
                <strong>Plataforma Digital</strong> que possam violar leis de
                direitos autorais, as leis de privacidade e publicidade e/ou
                regulamentos ou regras associadas a comunicações;
              </p>
            </li>
            <li>
              <p>
                utilizar a <strong>Plataforma Digital</strong> de qualquer forma
                que seja concebida para prejudicar ou que uma pessoa razoável
                pudesse entender que resultaria em dano para outro{" "}
                <strong>Usuário</strong> ou terceiros, dentro do plausível;
              </p>
            </li>
            <li>
              <p>passar-se por outra pessoa;</p>
            </li>
            <li>
              <p>
                permitir que qualquer outra pessoa se passe pelo{" "}
                <strong>Usuário</strong> para acessar, utilizar ou registrar-se
                na <strong>Plataforma Digital</strong>;
              </p>
            </li>
            <li>
              <p>
                acessar ou utilizar a <strong>Plataforma Digital</strong> para
                fins comerciais ou competitivos, a fim de divulgação de
                negócios, vendas de produtos e serviços que não sejam aprovados
                pela <strong>SYMBORA</strong>;
              </p>
            </li>
            <li>
              <p>
                Executar ações e/ou fornecer informações falsas visando violar
                os sistemas de pagamento, reembolso, seguros ou qualquer outro
                aspecto da <strong>Plataforma Digital</strong>;
              </p>
            </li>
            <li>
              <p>
                Fornecer informações falsas, incongruentes, desencontradas ou
                que não possam ser provadas visando violar estes Termos;
              </p>
            </li>
            <li>
              <p>
                Mencionar, oferecer e/ou aceitar receber valores por fora da{" "}
                <strong>Plataforma Digital</strong> referente a prestações dos
                Serviços;
              </p>
            </li>
            <li>
              <p>
                Agir de forma discriminatória, seja por cor, gênero, posição
                social, orientação sexual, deficiência física e/ou mental,
                posição política e/ou religiosa com membro da equipe da{" "}
                <strong>SYMBORA</strong>;
              </p>
            </li>
          </ol>
          <p>
            7.2.O <strong>Usuário</strong> concorda que o término de seu acesso
            à <strong>Plataforma Digital</strong>, por qualquer razão constante
            destes Termos, pode ocorrer sem uma notificação prévia e todas as
            informações e dados constantes poderão ser permanentemente apagados,
            ressalvadas disposições legais específicas quanto à manutenção de
            dados.
          </p>
          <p>
            7.3.A <strong>SYMBORA</strong> se reserva o direito de agir
            judicialmente e extrajudicialmente em casos de danos sofridos pela{" "}
            <strong>SYMBORA</strong> ou por terceiros, inclusive poderá entrar
            em contato com as autoridades e dar início à instrução de processos
            criminais, civis e administrativos nos casos previstos pela lei,
            quando julgar necessário.
          </p>
          <p>
            7.4.A suspensão ou resilição destes Termos de Uso pela{" "}
            <strong>SYMBORA</strong> não isenta o <strong>Usuários</strong> do
            pagamento de quaisquer valores eventualmente devidos à
            <strong>&nbsp;SYMBORA</strong>, que seguirão sendo passíveis de
            cobrança e de compensação.
          </p>
          <p>
            7.5.O <strong>Usuários</strong> não fará jus a qualquer indenização
            por perdas e danos, incluindo, mas sem se limitar a isto, a lucros
            cessantes, compensação por danos morais, ou outras formas de
            indenização previstas no ordenamento jurídico brasileiro. seja pela
            suspensão ou resilição destes Termos de Uso pela{" "}
            <strong>SYMBORA</strong>.
          </p>
          <p>
            <u>
              <strong>8.</strong>
            </u>
            <u>
              <strong>PROPRIEDADE INTELECTUAL</strong>
            </u>
          </p>
          <p>
            8.1.As marcas, nomes, logotipos, nomes de domínio e demais sinais
            distintivos, bem como todo e qualquer conteúdo, desenho, arte ou{" "}
            <em>layout</em> publicado pela <strong>SYMBORA</strong>, ou por meio
            da <strong>Plataforma Digital</strong>, são de propriedade exclusiva
            da <strong>SYMBORA</strong>.
          </p>
          <p>
            8.2.São vedados quaisquer atos ou contribuições tendentes à
            descompilação, engenharia reversa, modificação das características,
            ampliação, alteração, mesclagem ou incorporação em quaisquer outros
            programas ou sistemas da <strong>Plataforma Digital</strong>, ou dos
            Serviços, em sentido amplo. Enfim, toda e qualquer forma de
            reprodução da <strong>Plataforma Digital</strong>, ou dos Serviços
            em si, total ou parcial, permanente, temporária ou provisória, de
            forma gratuita ou onerosa, sob quaisquer modalidades, formas ou
            títulos é expressamente vedada.
          </p>
          <p>
            <u>
              <strong>9.</strong>
            </u>
            <u>
              <strong>DISPOSIÇÕES FINAIS</strong>
            </u>
          </p>
          <p>
            9.1.Estes Termos constituem o acordo integral entre o{" "}
            <strong>Usuário</strong> e a <strong>SYMBORA</strong> e regerá a
            utilização da <strong>Plataforma Digital</strong> pelo{" "}
            <strong>Usuário</strong>, bem como a utilização dos Serviços pelo{" "}
            <strong>Usuários</strong>, substituindo qualquer acordo anterior. A
            mera utilização da Plataforma concorda com tal condição.
          </p>
          <p>
            9.2.
            <u>
              <strong>Notificações</strong>
            </u>
            <strong>.</strong> Todas as notificações, intimações, ofícios ou
            qualquer outra forma oficial de intimação da{" "}
            <strong>SYMBORA</strong> deverão ocorrer em seu endereço de sede,
            descrito no preâmbulo destes Termos.
          </p>
          <p>
            9.3.
            <u>
              <strong>Renúncia de Direitos</strong>
            </u>
            . Caso a <strong>SYMBORA</strong> deixe de exercer ou executar
            qualquer direito ou dispositivo destes Termos, isto não será
            caracterizado como uma renúncia a tal direito ou dispositivo nem
            constituirá novação. Se qualquer dispositivo destes Termos for
            considerado inválido por um tribunal competente, as partes, todavia,
            concordam que o restante dos Termos deverá ser interpretado de tal
            forma a refletir a intenção original, e os outros dispositivos
            destes Termos permanecerão em pleno vigor e efeito.
          </p>
          <p>
            9.4.{" "}
            <u>
              <strong>Cessão de Direitos</strong>
            </u>
            . Fica expressamente vedada a cessão ou transferência destes Termos,
            total ou parcialmente, sem prévia aprovação por escrito da{" "}
            <strong>SYMBORA</strong>. O <strong>Usuário</strong> concede sua
            aprovação para que a <strong>SYMBORA</strong> ceda e transfira estes
            Termos total ou parcialmente, inclusive: (i) um adquirente das
            participações acionárias, negócios ou bens da{" "}
            <strong>SYMBORA</strong>; ou (ii) para um sucessor em razão de
            qualquer operação societária. Não existe <em>joint-venture</em>,
            sociedade, emprego ou relação de representação entre o a{" "}
            <strong>SYMBORA</strong> ou quaisquer <strong>Usuários</strong> como
            resultado da aceitação destes Termos ou em razão da prestação dos
            serviços.
          </p>
          <p>
            9.5.
            <u>
              <strong>Indivisibilidade</strong>
            </u>
            <strong>.</strong> Caso qualquer disposição destes Termos seja tida
            como ilegal, inválida ou inexequível total ou parcialmente, por
            qualquer legislação, essa disposição ou parte dela será, naquela
            medida, considerada como não existente para os efeitos destes
            Termos, mas a legalidade, validade e exequibilidade das demais
            disposições contidas nestes Termos não serão afetadas. Nesse caso,
            as partes substituirão a disposição ilegal, inválida ou inexequível,
            ou parte dela, por outra que seja legal, válida e exequível e que,
            na máxima medida possível, tenha efeito similar à disposição tida
            como ilegal, inválida ou inexequível para fins de conteúdo e
            finalidade dos presentes Termos. Estes Termos constituem a
            totalidade do acordo e entendimento das partes sobre este assunto e
            substituem e prevalecem sobre todos os entendimentos e compromissos
            anteriores sobre este assunto. Nestes Termos, as palavras
            “inclusive” e “inclui” significam “incluindo, sem limitação”.
          </p>
          <p>
            9.6.
            <u>
              <strong>Legislação Aplicável</strong>
            </u>
            . Estes Termos e o relacionamento entre o
            <strong>&nbsp;Usuário</strong> e a <strong>SYMBORA</strong> serão
            regidos pelas leis da República Federativa do Brasil. Fica eleito o
            Foro da Comarca da Capital do Estado de São Paulo como sendo o único
            competente para dirimir quaisquer litígios e/ou demandas que venham
            a envolver as partes em relação ao uso e acesso à{" "}
            <strong>Plataforma Digital</strong>, bem como em relação a quaisquer
            divergências comerciais. O <strong>Usuário</strong> e a{" "}
            <strong>SYMBORA</strong> concordam em submeter-se à competência
            única e exclusiva dos tribunais localizados no Brasil.
          </p>
        </div>
      </div>
    </div>
  );
};
